const formatCPF = (cpf: string) => {
	if (cpf) {
		return cpf.slice(0, 3) + '.' + cpf.slice(3, 6) + '.' + cpf.slice(6, 9) + '-' + cpf.slice(9, 11);
	}
	return '';
};

const formatCNPJ = (cnpj: string) => {
	if (cnpj) {
		return (
			cnpj.slice(0, 2) +
			'.' +
			cnpj.slice(2, 5) +
			'.' +
			cnpj.slice(5, 8) +
			'/' +
			cnpj.slice(8, 12) +
			'-' +
			cnpj.slice(12, 14)
		);
	}
	return '';
};

export { formatCPF, formatCNPJ };
