
import { Component, Vue } from 'vue-property-decorator';
import Order from '@/components/pages/modules/Order/index.vue';

@Component({
	components: {
		Order,
	},
})
export default class EscalatorFacilityOrder extends Vue {}
