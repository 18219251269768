/* Imports */
import api from '@/shared/util/api';
const { http } = api.getInstance();

const BASE_URL = '/uboi-escalador';

/* Actions */
import {
	ESCALATOR_ORDER_ORIGIN_RANCHER_REQUEST,
	ESCALATOR_ORDER_ORIGIN_FARM_REQUEST,
	ESCALATOR_TRACKING_TYPES_REQUEST,
	ESCALATOR_OPERATIONAL_SITUATION_REQUEST,
	ESCALATOR_STATUS_PROPOSAL_REQUEST,
	TRANSIT_ORDER_DESTINY_RANCHER_REQUEST,
	TRANSIT_TRAVEL_ORIGIN_RANCHER_REQUEST,
	TRANSIT_TRAVEL_DESTINY_RANCHER_REQUEST,
	TRANSIT_ORDER_DESTINY_FARM_REQUEST,
	TRANSIT_TRAVEL_ORIGIN_FARM_REQUEST,
	TRANSIT_TRAVEL_DESTINY_FARM_REQUEST,
	TRANSIT_DRIVERS_REQUEST,
	TRANSIT_PARTNER_MAP_REQUEST,
	TRANSIT_DRIVER_MAP_REQUEST,
	TRANSIT_TRAVEL_DRIVER_DETACHED_FORM_REQUEST,
} from './actions';

/* Vuex Auth Mutations */
const mutations = {};

/* Vuex Auth Actions */
const actions = {
	[ESCALATOR_OPERATIONAL_SITUATION_REQUEST]: () => {
		return new Promise<void>((resolve, reject) => {
			http({
				method: 'get',
				url: `${BASE_URL}/parceiro/situacao-operacional/tipos`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_ORDER_ORIGIN_RANCHER_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}

			http({
				method: 'get',
				url: `${BASE_URL}/parceiro/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_ORDER_ORIGIN_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.id) {
				queryParams.push(`idParceiro=${requestData.id}`);
			}
			http({
				method: 'get',
				url: `${BASE_URL}/parceiro/fazenda/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_STATUS_PROPOSAL_REQUEST]: () => {
		return new Promise<void>((resolve, reject) => {
			http({
				method: 'get',
				url: '/escalador/jbspedido/proposta-contrato-status-tipos',
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_TRACKING_TYPES_REQUEST]: () => {
		return new Promise<void>((resolve, reject) => {
			http({
				method: 'get',
				url: `${BASE_URL}/parceiro/rastreamento/tipos`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_DRIVER_DETACHED_FORM_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbsviagem-avulsa/motorista?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_ORDER_DESTINY_RANCHER_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbspedido/parceiro/destino/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_ORIGIN_RANCHER_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbsviagem/parceiro/origem/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_DESTINY_RANCHER_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbsviagem/parceiro/destino/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_ORDER_DESTINY_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.id) {
				queryParams.push(`idParceiro=${requestData.id}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbspedido/fazenda/destino/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_ORIGIN_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.id) {
				queryParams.push(`idParceiro=${requestData.id}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbsviagem/fazenda/origem/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_TRAVEL_DESTINY_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.id) {
				queryParams.push(`idParceiro=${requestData.id}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbsviagem/fazenda/destino/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_DRIVERS_REQUEST]: ({ commit }, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbsviagem/motorista/filtro/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_PARTNER_MAP_REQUEST]: ({ commit }, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbsviagem/parceiro/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_DRIVER_MAP_REQUEST]: ({ commit }, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}
			http({
				method: 'get',
				url: `/escalador/jbsviagem/motorista/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default {
	actions,
	mutations,
};
