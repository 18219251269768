
import { Vue, Component, Prop } from 'vue-property-decorator';
import Button from '../Button/Button.vue';

import buttonsIcons from '@/constants/buttonsIcons';

@Component({
	components: {
		Button,
	},
})
export default class IconButton extends Vue {
	/* Props */
	@Prop({ default: 'button' })
	public component!: string;

	@Prop({ default: 'detail' })
	public icon!: string;

	@Prop({ default: '#865946' })
	public color!: string;

	@Prop()
	public title!: string;

	@Prop({ default: false })
	public disabled!: boolean;

	@Prop({ default: false })
	public outline!: boolean;

	@Prop({ default: false })
	public filled!: boolean;

	/* Computed */
	get iconButtons() {
		return buttonsIcons;
	}

	get iconType() {
		if (this.outline) return 'outline';
		if (this.filled) return 'filled';
		return 'component';
	}
}
