
import { Vue, Component, Prop } from 'vue-property-decorator';
import Loader from '@/components/widgets/atoms/Loader/Loader.vue';

@Component({
	components: {
		Loader,
	},
})
export default class Modal extends Vue {
	/* Props */
	@Prop({ required: true })
	public id!: string;

	@Prop({ default: '' })
	public modalClass!: string;

	@Prop({ required: true })
	public size!: string;

	@Prop({ required: false })
	public visible!: boolean;

	@Prop({ required: false })
	public hide!: any;

	@Prop({ default: false })
	public noCloseOnBackdrop!: boolean;

	@Prop({ default: true })
	public centered!: boolean;

	@Prop({ default: false })
	public loading!: boolean;
}
