
import { Component, Vue } from 'vue-property-decorator';
import InputLogin from '@/components/widgets/atoms/InputLogin/InputLogin.vue';
import InputPassword from '@/components/widgets/atoms/InputPassword/InputPassword.vue';
import ReCaptcha from '@/components/widgets/atoms/ReCaptcha/ReCaptcha.vue';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';

import { AUTH_LOGIN_REQUEST } from '@/store/auth/actions';

@Component({
	components: {
		InputLogin,
		InputPassword,
		ReCaptcha,
		Button,
		ButtonCustom,
	},
})
export default class LoginForm extends Vue {
	/* Data */
	private login: string | null = null;

	private password: string | null = null;

	private captchaStatus: boolean = false;

	private shakeCaptcha: boolean = false;

	/* Computed */
	get routerName() {
		return this.$route.meta?.route;
	}

	get loginType() {
		switch (this.routerName) {
			default:
				return 'uboi-escalador';
		}
	}

	get isLoading() {
		return this.$store.getters.isLoginLoading;
	}

	/* Methods */
	async onAction() {
		return new Promise((resolve, reject) => {
		const grecaptcha: any = (window as any).grecaptcha;
  		grecaptcha.enterprise.ready(async () => {
			try {
				const token = await grecaptcha.enterprise.execute(process.env.VUE_APP_RECAPTCHA_KEY, { action: 'submit' });
				resolve(token);
			} catch {
				reject(this.$toast.error('Falha na validação do reCAPTCHA'))
			}
  })
})
}

	async doLogin() { 
			await this.onAction().then(()=> {
				const params = {
			login: this.login,
			senha: this.password,
			type: this.loginType,
			routerName: this.routerName,
		};

		this.$store
			.dispatch(AUTH_LOGIN_REQUEST, params)
			.then(({ isFirstLogin, isTemporaryPassword, hashcode }) => {
				if (isFirstLogin && isTemporaryPassword) return this.goFirstLogin();
				if (isTemporaryPassword) return this.goTemporary();
				this.$router.push({ path: `/two-factor-auth/escalator?hashcode=${hashcode}` });
			})
			.catch((e) => {
				this.$toast.error(e.response.data);
			});
			});
	}

	goTemporary() {
		return this.$router.push({
			path: '/recover-password',
			query: { identification: this.login, tempPassword: this.password },
		});
	}

	goFirstLogin() {
		switch (this.routerName) {
			default:
				return this.$router.push({
					path: '/first-login/escalador',
					query: { identification: this.login, tempPassword: this.password },
				});
		}
	}

	goForgot() {
		this.$router.push({ path: `/login/${this.routerName}/forgot` });
	}

	getCaptcha(status) {
		this.captchaStatus = status;
	}
}
