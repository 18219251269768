import xlsx from 'json-as-xlsx';

export const saveXLSX = (header, body, sheetName, fileName) => {
	const settings = {
		sheetName,
		fileName,
		extraLength: 3,
	};

	//do not remove true param, he allows to download the file
	xlsx(header, body, settings, true);
};
