
import { Component, Vue, Watch } from 'vue-property-decorator';
import UboiLogo from '@/components/icons/UboiLogo.vue';
import RoundedCheck from '@/components/icons/RoundedCheck.vue';
import ArrowRoundedIcon from '@/components/icons/ArrowRounded.vue';
import TwoFactorAuthForm from '@/components/pages/login/organisms/TwoFactorAuthForm/index.vue';

import { CONSTANTS } from '@/constants/constants';

import { RESEND_MFA_TOKEN_REQUEST } from '@/store/auth/actions';

@Component({
	components: {
		UboiLogo,
		RoundedCheck,
		ArrowRoundedIcon,
		TwoFactorAuthForm,
	},
})
export default class TwoAuthRancher extends Vue {
	/* Data */
	private DEFAULT_INTERVAL: number = 59;

	public interval: number = this.DEFAULT_INTERVAL;

	public intervalHandler: any = null;

	/* Computed */
	get routerName() {
		return this.$route.meta?.route;
	}

	get formattedInterval() {
		return this.interval.toString().padStart(2, '0');
	}

	/* Lifecycle */
	created() {
		this.startCountDown();
	}

	/* Watcher */
	@Watch('interval', { immediate: true })
	onIntervalChange(value) {
		if (value < 0) {
			clearInterval(this.intervalHandler);
		}
	}

	get loginType() {
		switch (this.routerName) {
			default:
				return 'uboi-escalador';
		}
	}

	get version() {
		return CONSTANTS.SYSTEM_VERSION;
	}

	/* Methods */
	resendMfaToken() {
		const params = {
			hashcode: this.$route.query.hashcode,
			type: this.loginType,
		};

		this.$store
			.dispatch(RESEND_MFA_TOKEN_REQUEST, params)
			.then(({ hashcode }) => {
				this.$router.push({ path: `/two-factor-auth/escalator?hashcode=${hashcode}` });
				this.$toast.success('Email enviado com sucesso.');
				this.startCountDown();
			})
			.catch((e) => {
				this.$toast.error(e.response.data);
			});
	}

	backToLogin() {
		this.$router.push({ path: '/login' });
	}

	decrementInterval() {
		this.interval = this.interval - 1;
	}

	startCountDown() {
		this.interval = this.DEFAULT_INTERVAL;
		this.intervalHandler = setInterval(() => {
			this.decrementInterval();
		}, 1000);
	}
}
