/* Imports */
import OrderState from '@/models/TransitFacility/order-state/order-state.interface';
import api from '@/shared/util/api';

const BASE_URL = '/uboi-escalador';

const { http } = api.getInstance();

/* Actions */
import {
	ESCALATOR_TRUCK_TYPES_REQUEST,
	ESCALATOR_SHIPPING_COMPANY_REQUEST,
	ESCALATOR_LOAD_ORDER_REQUEST,
	ESCALATOR_LOAD_ORDER_SUCCESS,
	ESCALATOR_LOAD_ORDER_FAILED,
	ESCALATOR_ORIGINATION_LOAD_ORDER_REQUEST,
	ESCALATOR_ORIGINATION_LOAD_ORDER_SUCCESS,
	ESCALATOR_ORIGINATION_LOAD_ORDER_FAILED,
	ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST,
	ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST_2,
	ESCALATOR_ORIGINATION_ORDER_DETAIL_SUCCESS,
	ESCALATOR_ORIGINATION_ORDER_DETAIL_FAILED,
	ESCALATOR_LOAD_CONTRACTS_REQUEST,
	ESCALATOR_LOAD_CONTRACTS_SUCCESS,
	ESCALATOR_LOAD_CONTRACTS_FAILED,
	ESCALATOR_ORDER_DETAIL_REQUEST,
	TRANSIT_ORDER_DETAIL_SUCCESS,
	TRANSIT_ORDER_DETAIL_FAILED,
	ESCALATOR_CONTRACT_DETAIL_REQUEST,
	ESCALATOR_CONTRACT_DETAIL_SUCCESS,
	ESCALATOR_CONTRACT_DETAIL_FAILED,
	ESCALATOR_POST_PROPOSAL,
	TRANSIT_POST_CONTRACT,
	ESCALATOR_PUT_TRAVEL_REQUEST,
	ESCALATOR_PUT_TRAVEL_SUCCESS,
	ESCALATOR_PUT_TRAVEL_FAILED,
	ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST,
	ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST_2,
	ESCALATOR_ORIGINATION_POST_TRAVEL_SUCCESS,
	ESCALATOR_ORIGINATION_POST_TRAVEL_FAILED,
	ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST,
	ESCALATOR_ORIGINATION_PUT_TRAVEL_SUCCESS,
	ESCALATOR_ORIGINATION_PUT_TRAVEL_FAILED,
	TRANSIT_PUT_CONTRACT,
	TRANSIT_DELETE_TRAVEL,
	ESCALATOR_SELECT_ORDER,
	ESCALATOR_SELECT_CONTRACT,
	ESCALATOR_SELECT_ORIGINATION,
	ESCALATOR_ORIGINATION_DETAIL_REQUEST,
	ESCALATOR_ORIGINATION_DETAIL_FAILED,
	ESCALATOR_ORIGINATION_DETAIL_SUCCESS,
	ESCALATOR_POST_TRAVEL_REQUEST,
	ESCALATOR_POST_TRAVEL_FAILED,
	ESCALATOR_POST_TRAVEL_SUCCESS,
	ESCALATOR_CANCEL_ORDER_REQUEST,
	ESCALATOR_CANCEL_ORDER_SUCCESS,
	ESCALATOR_CANCEL_ORDER_FAILED,
	ESCALATOR_ORIGINATION_DELETE_TRAVEL_REQUEST,
	TRANSIT_RELOAD_TRAVEL,
	ESCALATOR_EXPORTXLSX,
	ESCALATOR_SET_ROADMAP,
	ESCALATOR_SCALE_ORDER,
	ESCALATOR_SCALE_TO_JBS,
	ESCALATOR_SCALE_TO_TRANSIT_FACILITIES,
	ESCALATOR_ORIGINATION_SET_POST_TRAVEL,
	ESCALATOR_DELETED_TRAVEL
} from './actions';

/* Initial AuthState object */
const state: OrderState = {
	orderStatus: '',
	orderDetailStatus: '',
	contractStatus: '',
	contractDetailStatus: '',
	scaleDetailStatus: '',
	postTravelStatus: '',
	putTravelStatus: '',
	selectedTransitOrder: null,
	selectedTransitContract: null,
	selectedOrigination: null,
	originationDetailStatus: '',
	cancelOrderStatus: '',
	reloadTravel: false,
	exportXlsx: null,
	roadmap: '',
	selectedScaleOrder: null,
	scaleOrderStatus: '',
	selectedPostTravel: '',
	deletedTravel: ''
};

/* Vuex Auth Getters */
const getters = {
	isTransitOrderLoading: (state: OrderState): string => state.orderStatus,
	isTransitOrderDetailLoading: (state: OrderState): boolean =>
		state.orderDetailStatus === 'loading',
	isTransitContractLoading: (state: OrderState): string => state.contractStatus,
	isTransitContractDetailLoading: (state: OrderState): boolean =>
		state.contractDetailStatus === 'loading',
	isScaleDetailLoading: (state: OrderState): boolean => state.scaleDetailStatus === 'loading',
	isTransitPostTravelLoading: (state: OrderState): boolean => state.postTravelStatus === 'loading',
	isTransitPutTravelLoading: (state: OrderState): boolean => state.putTravelStatus === 'loading',
	getSelectedTransitOrder: (state: OrderState) => state.selectedTransitOrder,
	hasSelectedTransitOrder: (state: OrderState): boolean => state.selectedTransitOrder !== null,
	getSelectedTransitContract: (state: OrderState) => state.selectedTransitContract,
	hasSelectedTransitContract: (state: OrderState): boolean =>
		state.selectedTransitContract !== null,
	getSelectedOrigination: (state: OrderState) => state.selectedOrigination,
	hasSelectedOrigination: (state: OrderState): boolean => state.selectedOrigination !== null,
	isOriginationLoading: (state: OrderState): boolean => state.originationDetailStatus === 'loading',
	isCancelingOrigination: (state: OrderState): boolean => state.cancelOrderStatus === 'loading',
	isReloadTravel: (state: OrderState): boolean => state.reloadTravel,
	hasOrderExportXlsx: (state: OrderState): boolean => state.exportXlsx === 'orders',
	hasContractExportXlsx: (state: OrderState): boolean => state.exportXlsx === 'contracts',
	roadmap: (state: OrderState): string => state.roadmap,
	hasSelectedScaleOrder: (state: OrderState): boolean => state.selectedScaleOrder !== null,
	getSelectedScaleOrder: (state: OrderState) => state.selectedScaleOrder,
	isScaleOrderLoading: (state: OrderState): boolean => state.scaleOrderStatus === 'loading',
	getSelectedPostTravel: (state: OrderState) => state.selectedPostTravel,
	hasSelectedPostTravel: (state: OrderState): boolean => state.selectedPostTravel !== ''
};

/* Vuex Auth Mutations */
const mutations = {
	[ESCALATOR_LOAD_ORDER_REQUEST]: (state: OrderState) => {
		state.orderStatus = 'loading';
	},
	[ESCALATOR_LOAD_ORDER_SUCCESS]: (state: OrderState) => {
		state.orderStatus = 'success';
	},
	[ESCALATOR_LOAD_ORDER_FAILED]: (state: OrderState) => {
		state.orderStatus = 'error';
	},
	[ESCALATOR_ORIGINATION_LOAD_ORDER_REQUEST]: (state: OrderState) => {
		state.orderStatus = 'loading';
	},
	[ESCALATOR_ORIGINATION_LOAD_ORDER_SUCCESS]: (state: OrderState) => {
		state.orderStatus = 'success';
	},
	[ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST]: (state: OrderState) => {
		state.scaleDetailStatus = 'loading';
	},
	[ESCALATOR_ORIGINATION_ORDER_DETAIL_SUCCESS]: (state: OrderState) => {
		state.scaleDetailStatus = 'success';
	},
	[ESCALATOR_ORIGINATION_ORDER_DETAIL_FAILED]: (state: OrderState) => {
		state.scaleDetailStatus = 'error';
	},
	[ESCALATOR_LOAD_CONTRACTS_REQUEST]: (state: OrderState) => {
		state.contractStatus = 'loading';
	},
	[ESCALATOR_LOAD_CONTRACTS_SUCCESS]: (state: OrderState) => {
		state.contractStatus = 'success';
	},
	[ESCALATOR_LOAD_CONTRACTS_FAILED]: (state: OrderState) => {
		state.contractStatus = 'error';
	},
	[ESCALATOR_ORDER_DETAIL_REQUEST]: (state: OrderState) => {
		state.orderDetailStatus = 'loading';
	},
	[TRANSIT_ORDER_DETAIL_SUCCESS]: (state: OrderState) => {
		state.orderDetailStatus = 'success';
	},
	[TRANSIT_ORDER_DETAIL_FAILED]: (state: OrderState) => {
		state.orderDetailStatus = 'error';
	},
	[ESCALATOR_CONTRACT_DETAIL_REQUEST]: (state: OrderState) => {
		state.contractDetailStatus = 'loading';
	},
	[ESCALATOR_CONTRACT_DETAIL_SUCCESS]: (state: OrderState) => {
		state.contractDetailStatus = 'success';
	},
	[ESCALATOR_CONTRACT_DETAIL_FAILED]: (state: OrderState) => {
		state.contractDetailStatus = 'error';
	},
	[ESCALATOR_POST_TRAVEL_REQUEST]: (state: OrderState) => {
		state.postTravelStatus = 'loading';
	},
	[ESCALATOR_POST_TRAVEL_SUCCESS]: (state: OrderState) => {
		state.postTravelStatus = 'success';
	},
	[ESCALATOR_POST_TRAVEL_FAILED]: (state: OrderState) => {
		state.postTravelStatus = 'error';
	},
	[ESCALATOR_PUT_TRAVEL_REQUEST]: (state: OrderState) => {
		state.putTravelStatus = 'loading';
	},
	[ESCALATOR_PUT_TRAVEL_SUCCESS]: (state: OrderState) => {
		state.putTravelStatus = 'success';
	},
	[ESCALATOR_PUT_TRAVEL_FAILED]: (state: OrderState) => {
		state.putTravelStatus = 'error';
	},
	[ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST]: (state: OrderState) => {
		state.postTravelStatus = 'loading';
	},
	[ESCALATOR_ORIGINATION_POST_TRAVEL_SUCCESS]: (state: OrderState) => {
		state.postTravelStatus = 'success';
	},
	[ESCALATOR_ORIGINATION_POST_TRAVEL_FAILED]: (state: OrderState) => {
		state.postTravelStatus = 'error';
	},
	[ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST]: (state: OrderState) => {
		state.putTravelStatus = 'loading';
	},
	[ESCALATOR_ORIGINATION_PUT_TRAVEL_SUCCESS]: (state: OrderState) => {
		state.putTravelStatus = 'success';
	},
	[ESCALATOR_ORIGINATION_PUT_TRAVEL_FAILED]: (state: OrderState) => {
		state.putTravelStatus = 'error';
	},
	[ESCALATOR_SELECT_ORDER]: (state, order) => {
		state.selectedTransitOrder = order;
	},
	[ESCALATOR_SELECT_ORIGINATION]: (state, order) => {
		state.selectedOrigination = order;
	},
	[ESCALATOR_SELECT_CONTRACT]: (state, contract) => {
		state.selectedTransitContract = contract;
	},
	[ESCALATOR_ORIGINATION_DETAIL_REQUEST]: (state) => {
		state.originationDetailStatus = 'loading';
	},
	[ESCALATOR_ORIGINATION_DETAIL_SUCCESS]: (state) => {
		state.originationDetailStatus = 'success';
	},
	[ESCALATOR_ORIGINATION_DETAIL_FAILED]: (state) => {
		state.originationDetailStatus = 'error';
	},
	[ESCALATOR_POST_TRAVEL_REQUEST]: (state) => {
		state.postTravelStatus = 'loading';
	},
	[ESCALATOR_POST_TRAVEL_FAILED]: (state) => {
		state.postTravelStatus = 'error';
	},
	[ESCALATOR_POST_TRAVEL_SUCCESS]: (state) => {
		state.postTravelStatus = 'success';
	},
	[ESCALATOR_CANCEL_ORDER_REQUEST]: (state) => {
		state.cancelOrderStatus = 'loading';
	},
	[ESCALATOR_CANCEL_ORDER_SUCCESS]: (state) => {
		state.cancelOrderStatus = 'success';
	},
	[ESCALATOR_CANCEL_ORDER_FAILED]: (state) => {
		state.cancelOrderStatus = 'error';
	},
	[TRANSIT_RELOAD_TRAVEL]: (state, reload) => {
		state.reloadTravel = reload;
	},
	[ESCALATOR_EXPORTXLSX]: (state, xlsx) => {
		state.exportXlsx = xlsx;
	},
	[ESCALATOR_SET_ROADMAP]: (state, roadmap) => {
		state.roadmap = roadmap;
	},
	[ESCALATOR_SCALE_ORDER]: (state, order) => {
		state.selectedScaleOrder = order;
	},
	[ESCALATOR_ORIGINATION_SET_POST_TRAVEL]: (state, travel) => {
		state.selectedPostTravel = travel
	},
	[ESCALATOR_DELETED_TRAVEL]: (state, value) => {
		state.deletedTravel = value
	}
};

/* Vuex Auth Actions */
const actions = {
	[ESCALATOR_LOAD_ORDER_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			Object.keys(requestData).forEach((data) => {
				if (requestData[data] && data !== 'page' && data !== 'size') {
					queryParams.push(`${data}=${requestData[data]}`);
				}
			});
			commit(ESCALATOR_LOAD_ORDER_REQUEST);
			http({
				method: 'get',
				url: `${BASE_URL}/pedido?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(ESCALATOR_LOAD_ORDER_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_LOAD_ORDER_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_ORIGINATION_LOAD_ORDER_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			Object.keys(requestData).forEach((data) => {
				if (requestData[data] && data !== 'page' && data !== 'size') {
					queryParams.push(`${data}=${requestData[data]}`);
				}
			});
			commit(ESCALATOR_LOAD_ORDER_REQUEST);
			http({
				method: 'get',
				url: `${BASE_URL}/pedido/originacao?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(ESCALATOR_ORIGINATION_LOAD_ORDER_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_ORIGINATION_LOAD_ORDER_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_LOAD_CONTRACTS_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			Object.keys(requestData).forEach((data) => {
				if (requestData[data] && data !== 'page' && data !== 'size') {
					queryParams.push(`${data}=${requestData[data]}`);
				}
			});
			commit(ESCALATOR_LOAD_CONTRACTS_REQUEST);
			http({
				method: 'get',
				url: `${BASE_URL}/pedido/contrato-propostas?${queryParams.join('&')}`,
				headers: {
					'X-ApiKey': 'f77cb10d-37a5-41e7-b352-b8bb21ec6019',
				},
			})
				.then(({ data }) => {
					commit(ESCALATOR_LOAD_CONTRACTS_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_LOAD_CONTRACTS_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_ORDER_DETAIL_REQUEST]: ({ commit }: any, pedidoId: number) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_ORDER_DETAIL_REQUEST);

			http({
				method: 'get',
				url: `${BASE_URL}/pedido/detalhe/${pedidoId}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_ORDER_DETAIL_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(TRANSIT_ORDER_DETAIL_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST]: ({ commit }: any, pedidoId: number) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST);

			http({
				method: 'get',
				url: `${BASE_URL}/pedido/originacao/detalhe/${pedidoId}`,
			})
				.then(({ data }) => {
					commit(ESCALATOR_ORIGINATION_ORDER_DETAIL_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_ORIGINATION_ORDER_DETAIL_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST_2]: ({ commit }: any, pedidoId: number) => {
		return new Promise((resolve, reject) => {
			//commit(ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST);

			http({
				method: 'get',
				url: `${BASE_URL}/pedido/originacao/detalhe/${pedidoId}`,
			})
				.then(({ data }) => {
					commit(ESCALATOR_ORIGINATION_ORDER_DETAIL_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_ORIGINATION_ORDER_DETAIL_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_TRUCK_TYPES_REQUEST]: () => {
		return new Promise<void>((resolve, reject) => {
			http({
				method: 'get',
				url: `${BASE_URL}/parceiro/caminhao/tipos`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_SHIPPING_COMPANY_REQUEST]: () => {
		return new Promise<void>((resolve, reject) => {
			http({
				method: 'get',
				url: `${BASE_URL}/parceiro/busca/transportadora`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_CONTRACT_DETAIL_REQUEST]: ({ commit }: any, pedidoId: number) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_CONTRACT_DETAIL_REQUEST);

			http({
				method: 'get',
				url: `${BASE_URL}/pedido/proposta/detalhe/${pedidoId}`,
			})
				.then(({ data }) => {
					commit(ESCALATOR_CONTRACT_DETAIL_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_CONTRACT_DETAIL_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_POST_PROPOSAL]: ({ commit }: any, pedidoId: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/escalador/jbspedido/gerar-nova-proposta/${pedidoId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_SCALE_TO_JBS]: ({ commit }: any, pedidoId: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `${BASE_URL}/pedido/enviar-jbs/${pedidoId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_SCALE_TO_TRANSIT_FACILITIES]: ({ commit }: any, pedidoId: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `${BASE_URL}/pedido/enviar-transportadora/${pedidoId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_POST_TRAVEL_REQUEST]: ({ commit }: any, params: any) => {
		return new Promise((resolve, reject) => {
			const { pedidoId, requestData } = params;
			commit(ESCALATOR_POST_TRAVEL_REQUEST);
			http({
				method: 'post',
				url: `${BASE_URL}/pedido/proposta/viagem/${pedidoId}`,
				data: [requestData],
			})
				.then(({ data }) => {
					commit(ESCALATOR_POST_TRAVEL_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_POST_TRAVEL_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_PUT_TRAVEL_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_PUT_TRAVEL_REQUEST);

			http({
				method: 'put',
				url: `${BASE_URL}/pedido/proposta/viagem`,
				data: {
					...requestData,
				},
			})
				.then(({ data }) => {
					commit(ESCALATOR_PUT_TRAVEL_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_PUT_TRAVEL_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_DELETE_TRAVEL]: ({ commit }: any, travelId: number) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'delete',
				url: `${BASE_URL}/pedido/proposta/viagem/${travelId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_POST_CONTRACT]: ({ commit }: any, pedidoId: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `${BASE_URL}/pedido/proposta/gerar-contrato/${pedidoId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_PUT_CONTRACT]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'put',
				url: `${BASE_URL}/pedido/proposta`,
				data: {
					...requestData,
				},
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[ESCALATOR_SELECT_ORIGINATION]: ({ commit }, order) => {
		commit(ESCALATOR_SELECT_ORIGINATION, order);
	},
	[ESCALATOR_SELECT_ORDER]: ({ commit }, order) => {
		commit(ESCALATOR_SELECT_ORDER, order);
	},
	[ESCALATOR_SCALE_ORDER]: ({ commit }, order) => {
		commit(ESCALATOR_SCALE_ORDER, order);
	},
	[ESCALATOR_SELECT_CONTRACT]: ({ commit }, contract) => {
		commit(ESCALATOR_SELECT_CONTRACT, contract);
	},
	[ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST]: ({ commit }: any, params: any) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST);
			const { requestData, index } = params;
			
			http({
				method: 'post',
				url: `${BASE_URL}/viagem`,
				data: requestData,
			})
				.then(({ data }) => {
					commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_SUCCESS);
					commit(ESCALATOR_ORIGINATION_POST_TRAVEL_SUCCESS);
					commit(ESCALATOR_ORIGINATION_SET_POST_TRAVEL, index);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_FAILED);
					commit(ESCALATOR_ORIGINATION_POST_TRAVEL_FAILED);
					commit(ESCALATOR_ORIGINATION_SET_POST_TRAVEL, '');
					reject(error);
				});
		});
	},
	[ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST_2]: ({ commit }: any, params: any) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST);
			const { requestData, index } = params;
			
			http({
				method: 'post',
				url: `${BASE_URL}/viagem`,
				data: requestData,
			})
				.then(({ data }) => {
					commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_SUCCESS);
					commit(ESCALATOR_ORIGINATION_POST_TRAVEL_SUCCESS);
					commit(ESCALATOR_ORIGINATION_SET_POST_TRAVEL, index);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_FAILED);
					commit(ESCALATOR_ORIGINATION_POST_TRAVEL_FAILED);
					commit(ESCALATOR_ORIGINATION_SET_POST_TRAVEL, '');
					reject(error);
				});
		});
	},
	[ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST]: ({ commit }: any, params: any) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST);

			http({
				method: 'put',
				url: `${BASE_URL}/viagem`,
				data: params,
			})
				.then(({ data }) => {
					commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_ORIGINATION_DELETE_TRAVEL_REQUEST]: ({ commit }: any, idViagem: any) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST);

			http({
				method: 'delete',
				url: `${BASE_URL}/viagem/originacao/cancelar/${idViagem}`,
			})
				.then(({ data }) => {
					commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_ORIGINATION_PUT_TRAVEL_FAILED);
					reject(error);
				});
		});
	},
	[ESCALATOR_CANCEL_ORDER_REQUEST]: ({ commit }: any, orderId: number) => {
		return new Promise((resolve, reject) => {
			commit(ESCALATOR_CANCEL_ORDER_REQUEST);

			http({
				method: 'DELETE',
				url: `${BASE_URL}/pedido/cancelar/${orderId}`,
			})
				.then(({ data }) => {
					commit(ESCALATOR_CANCEL_ORDER_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_CANCEL_ORDER_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_RELOAD_TRAVEL]: ({ commit }, reload: boolean) => {
		commit(TRANSIT_RELOAD_TRAVEL, reload);
	},
	[ESCALATOR_EXPORTXLSX]: ({ commit }, xlsx) => {
		commit(ESCALATOR_EXPORTXLSX, xlsx);
	},
	[ESCALATOR_SET_ROADMAP]: ({ commit }, roadmap: string) => {
		commit(ESCALATOR_SET_ROADMAP, roadmap);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
