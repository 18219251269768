import HomeIcon from '@/components/icons/Order.vue';
import TravelIcon from '@/components/icons/Truck.vue';
import MapsIcon from '@/components/icons/PointMapBigger.vue';
import RegisterIcon from '@/components/icons/Register.vue';
import PermissionsIcon from '@/components/icons/People.vue';
import TravelDetached from '@/components/icons/TravelDetached.vue';

export default {
	Pedidos: HomeIcon,
	Viagens: TravelIcon,
	Avulsas: TravelDetached,
	Mapa: MapsIcon,
	Cadastro: RegisterIcon,
	Permissões: PermissionsIcon,
};
