/* Imports */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

/* Modules Import */
import auth from './auth';
import escalator from './escalator';

/* Vuex Setup */
Vue.use(Vuex);

// TODO: Replace vuex-user-auth with actual cookie key
export default new Vuex.Store({
	modules: {
		auth,
		...escalator,
	},
	plugins: [
		createPersistedState({
			key: 'vuex-user-auth',
			paths: ['auth'],
			storage: {
				getItem: (key) => Cookies.get(key),
				removeItem: (key) => Cookies.remove(key),
				setItem: (key, value) => Cookies.set(key, value),
			},
		}),
	],
});
