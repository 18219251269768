export const GET_TRANSIT_PARTNER_REQUEST = 'GET_TRANSIT_PARTNER_REQUEST';
export const GET_TRANSIT_PARTNER_SUCCESS = 'GET_TRANSIT_PARTNER_SUCCESS';
export const GET_TRANSIT_PARTNER_FAILED = 'GET_TRANSIT_PARTNER_FAILED';

export const GET_TRANSIT_ORIGIN_PARTNER_REQUEST = 'GET_TRANSIT_ORIGIN_PARTNER_REQUEST';
export const GET_TRANSIT_ORIGIN_PARTNER_SUCCESS = 'GET_TRANSIT_ORIGIN_PARTNER_SUCCESS';
export const GET_TRANSIT_ORIGIN_PARTNER_FAILED = 'GET_TRANSIT_ORIGIN_PARTNER_FAILED';

export const GET_TRANSIT_ORIGIN_FARM_REQUEST = 'GET_TRANSIT_ORIGIN_FARM_REQUEST';
export const GET_TRANSIT_ORIGIN_FARM_SUCCESS = 'GET_TRANSIT_ORIGIN_FARM_SUCCESS';
export const GET_TRANSIT_ORIGIN_FARM_FAILED = 'GET_TRANSIT_ORIGIN_FARM_FAILED';

export const GET_TRANSIT_DESTINY_PARTNER_REQUEST = 'GET_TRANSIT_DESTINY_PARTNER_REQUEST';
export const GET_TRANSIT_DESTINY_PARTNER_SUCCESS = 'GET_TRANSIT_DESTINY_PARTNER_SUCCESS';
export const GET_TRANSIT_DESTINY_PARTNER_FAILED = 'GET_TRANSIT_DESTINY_PARTNER_FAILED';

export const GET_TRANSIT_DESTINY_FARM_REQUEST = 'GET_TRANSIT_DESTINY_FARM_REQUEST';
export const GET_TRANSIT_DESTINY_FARM_SUCCESS = 'GET_TRANSIT_DESTINY_FARM_SUCCESS';
export const GET_TRANSIT_DESTINY_FARM_FAILED = 'GET_TRANSIT_DESTINY_FARM_FAILED';

export const POST_TRANSIT_NEW_ORDER = 'POST_TRANSIT_NEW_ORDER';
