
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class InputMoney extends Vue {
	/* Props */
	@Prop()
	public value!: string;

	@Prop({ default: 'input' })
	public name!: string;

	@Prop({ default: 'text' })
	public type!: string;

	@Prop({ default: 'default' })
	public variant!: string;

	@Prop({ default: false })
	public disabled!: boolean;

	@Prop({ default: false })
	public required!: boolean;

	@Prop()
	public placeholder!: string;

	/* Data */
	private money: any = {
		decimal: ',',
		thousands: '.',
		prefix: 'R$ ',
		precision: 2,
		masked: false,
	};

	/* Computed */
	get variantClass() {
		return `input-money--${this.variant}`;
	}
}
