
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueMultiselect from 'vue-multiselect';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import DeleteIcon from '@/components/icons/Close.vue';

@Component({
	components: {
		VueMultiselect,
		Button,
		DeleteIcon,
	},
})
export default class Multiselect extends Vue {
	@Prop({ default: false })
	public isSearch!: boolean;

	@Prop({ required: true })
	public options!: any;

	@Prop({ default: 'value' })
	public trackBy!: string;

	@Prop({ default: 'text' })
	public label!: string;

	@Prop()
	public name!: string;

	@Prop({ default: false })
	public loading!: boolean;

	@Prop({ default: false })
	public multiple!: boolean;

	@Prop({ default: 'Value' })
	public selectionTitle!: string;

	@Prop({ default: 'Selecionar' })
	public placeholder!: string;

	@Prop({ required: true })
	public value!: any;

	@Prop({ default: false })
	public disabled!: boolean;

	@Prop({ default: false })
	public hasDelete!: boolean;

	@Prop({ default: true })
	public allowEmpty!: boolean;

	private debounce: any = null;

	get model() {
		return this.value;
	}

	set model(value) {
		this.$emit('input', value);
	}

	get optionLength() {
		return this.value.length;
	}

	debounceSearch(search) {
		this.$emit('inputSearch', search);
		clearTimeout(this.debounce);
		this.debounce = setTimeout(() => {
			this.$emit('debounceSearch', search);
		}, 600);
	}
}
