
import { Component, Vue } from 'vue-property-decorator';
import PasswordRules from '@/components/widgets/atoms/PasswordRules/PasswordRules.vue';
import PasswordInput from '@/components/widgets/molecules/PasswordInput/PasswordInput.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';

import { CHANGE_PASSWORD } from '@/store/auth/actions';

@Component({
	components: {
		PasswordRules,
		PasswordInput,
		ButtonCustom,
	},
})
export default class ResetPasswordForm extends Vue {
	/* Data */
	public newPassword: string | null = null;

	private confirmPassword: string | null = null;

	/* Computed */
	get isLoading() {
		return this.$store.getters.isLoginLoading;
	}

	get identification() {
		return this.$route.query?.identification || '';
	}

	get tempPassword() {
		return this.$route.query?.tempPassword || '';
	}

	/* Methods */
	sendNewPassword() {
		const regex = new RegExp(
			'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-zd@$!%*?&0-9].{8,}$',
		);
		if (this.newPassword != this.confirmPassword) {
			this.$toast.error('Senhas não coincidem');
			return;
		}
		if (!regex.test(this.newPassword || '')) {
			this.$toast.error('Senha inválida');
			return;
		}
		if (!this.newPassword || !this.confirmPassword) {
			this.$toast.error('Preencha todos os campos');
			return;
		}
		const params = {
			login: this.identification,
			senhaAtual: this.tempPassword,
			novaSenha: this.newPassword,
			type: 'uboi-escalador',
		};

		this.$store
			.dispatch(CHANGE_PASSWORD, params)
			.then(() => {
				this.$router.push({ name: 'Escalador Pedidos' });
			})
			.catch((e) => {
				this.$toast.error(e.response.data);
			});
	}
}
