
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Disclosure from '@/components/widgets/molecules/Disclosure/Disclosure.vue';
import FormInput from '@/components/widgets/molecules/FormInput/FormInput.vue';
import FormDate from '@/components/widgets/molecules/FormDate/FormDate.vue';
import FormSelect from '@/components/widgets/molecules/FormSelect/FormSelect.vue';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';
import PlusIcon from '@/components/icons/Plus.vue';
import LessIcon from '@/components/icons/Less.vue';
import EditIcon from '@/components/icons/Edit.vue';
import TrashIcon from '@/components/icons/Trash.vue';
import CategoryFields from '../CategoryFields/CategoryFields.vue';

import hasDuplicateValues from '@/shared/util/hasDuplicateValues';
import { formatDateHour, formatDateISO } from '@/shared/util/formatDate';

import { ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST } from '@/store/escalator/orders/actions';

import {
	ESCALATOR_TRAVEL_GET_DRIVERS,
	ESCALATOR_GET_TRUCKS,
	ESCALATOR_GET_CARTS,
} from '@/store/escalator/travels/actions';

import { ESCALATOR_GET_COMPANY_REQUEST } from '@/store/escalator/shippingCompany/actions';

import TravelForm from '../TravelForm/TravelForm.vue';

@Component({
	components: {
		Disclosure,
		FormInput,
		FormDate,
		FormSelect,
		PlusIcon,
		LessIcon,
		EditIcon,
		TrashIcon,
		Button,
		ButtonCustom,
		CategoryFields,
	},
})
export default class DisclosureTravelScale extends Vue {
	/* Props */
	@Prop({ default: false })
	public disabled!: boolean;

	@Prop({ default: false })
	public isEdition!: boolean;

	@Prop({ default: null })
	public travel!: string | number | null;

	@Prop({ required: true })
	public animalsRows!: Array<any>;

	@Prop({ required: true })
	public animals!: Array<any>;

	@Prop({ default: {} })
	public fields!: any;

	@Prop({ default: () => [] })
	public truckTypes!: Array<any>;

	@Prop({ default: () => [] })
	public drivers!: Array<any>;

	@Prop({ default: () => [] })
	public trucks!: Array<any>;

	@Prop({ default: () => [] })
	public carts!: Array<any>;

	@Prop({ default: () => [] })
	public forms!: TravelForm;

	@Prop({ default: () => [] })
	public companies!: any;

	@Prop({ default: '' })
	public roadMap!: string;

	/* Data */
	private selectOptions: any = {
		animalsRows: JSON.parse(JSON.stringify(this.animalsRows)),
		truckTypes: this.truckTypes,
		drivers: this.drivers,
		trucks: this.trucks,
		carts: this.carts,
		companies: this.companies,
	};

	private form: any = {
		truckType: null,
		dateInit: null,
		dateEnd: null,
		freight: 0,
		extra: 0,
		categoryAnimals: [],
	};

	private buttonBusy: any = {
		saveButton: false,
	};

	private active: boolean = false;

	private totalQuantity: any = 0;

	private isEdit: boolean = false;

	/* Computed */

	get selectedOrder() {
		return this.$store.getters.getSelectedTransitContract;
	}

	get isLoading() {
		return this.$store.getters.isTransitPutTravelLoading;
	}

	get copyAnimalsRows() {
		return JSON.parse(JSON.stringify(this.selectOptions.animalsRows));
	}

	get animalsOptions() {
		const animals: Array<any> = [];
		Object.keys(this.copyAnimalsRows[0]).forEach((item) => {
			if (this.copyAnimalsRows[0][item] > 0) {
				animals.push({
					value: item,
					text: item,
					max: this.copyAnimalsRows[0][item],
				});
			}
		});
		return animals;
	}

	get amountOfAnimals(): number {
		return this.fields.data.animais.reduce((total, animal) => total + animal.quantidade, 0);
	}

	get totalWeight(): number {
		return this.fields.data.animais.reduce((total, animal) => total + animal.pesoMedio, 0);
	}

	get availableCarts() {
		const carts = this.fields.carts
			.map((selectCart) => {
				return selectCart.cart?.value;
			})
			.filter(Boolean);
		return this.selectOptions.carts.filter((cart) => {
			return !carts.includes(cart.value);
		});
	}

	get validation() {
		return (
			!this.fields.carts.every((selectCart) => selectCart.cart) ||
			!(this.selectOptions.carts.length > this.fields.carts.length)
		);
	}

	get btnSaveLabel() {
		const { driver, truck, companies, carts } = this.fields;

		//const hasCart = carts.length ? Boolean(carts[0].cart.value) : true;
		const hasCompanies = Boolean(companies?.value);
		const hasDriver = Boolean(driver?.value);
		const hasTruck = Boolean(truck?.value);

		const hasAllFields = hasCompanies && hasDriver && hasTruck;

		if (!hasAllFields) {
			return 'Envio Transportadora';
		}

		if (this.isEdition) {
			return 'Finalizar edição';
		}

		return 'Gerar Escala';
	}

	/* Lifecycle */
	mounted() {
		this.totalQuantity = this.fields.animalsRows[0];
		Object.keys(this.fields.data).forEach((key) => {
			if (['qtdBoi', 'qtdVaca', 'qtdNovilho', 'qtdBezerro'].includes(key)) {
				const column = {
					qtdBoi: 'bois',
					qtdVaca: 'vacas',
					qtdNovilho: 'novilhas',
					qtdBezerro: 'bezerros',
				};
				this.selectOptions.animalsRows[0][column[key]] += this.fields.data[key];
			}
		});
		this.resetInfo();
		this.getCompanies();

		const companyId = this.fields.companies?.value;
		if (companyId) {
			this.getDrivers();
			this.getTrucks();
			this.getCarts();
			this.setDriver();
		}
	}

	/* Methods */
	populateFields() {
		this.getTrucks();
		this.getCarts();
		this.getDrivers();
	}

	getDrivers(search = null) {
		const idTransportadora = this.fields.companies?.value;
		this.$store
			.dispatch(ESCALATOR_TRAVEL_GET_DRIVERS, {
				page: 1,
				size: 20,
				search,
				idTransportadora,
			})
			.then(({ results }) => {
				this.selectOptions.drivers = results.map((driver) => ({
					...driver,
					value: driver.idParceiro,
					text: driver.nome,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getTrucks(search = null) {
		const idTransportadora = this.fields.companies?.value;
		this.$store
			.dispatch(ESCALATOR_GET_TRUCKS, { page: 1, size: 20, search, idTransportadora })
			.then(({ results }) => {
				this.selectOptions.trucks = results.map((truck) => ({
					value: truck.idCaminhao,
					text: truck.placa,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getCarts(search = null) {
		const idTransportadora = this.fields.companies?.value;
		this.$store
			.dispatch(ESCALATOR_GET_CARTS, { page: 1, size: 20, search, idTransportadora })
			.then(({ results }) => {
				this.selectOptions.carts = results.map((cart) => ({
					value: cart.idCarreta,
					text: cart.placa,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getCompanies(search = null) {
		this.$store
			.dispatch(ESCALATOR_GET_COMPANY_REQUEST, { page: 1, size: 20, search })
			.then(({ results }) => {
				this.selectOptions.companies = results.map((company) => ({
					value: company?.idParceiro,
					text: company?.nome,
				}));
			});
	}

	setAnimals(animal, quantity, max) {
		return { animal: { value: animal, text: animal, max }, quantity };
	}

	handleChangeShippingCompany(e) {
		this.$emit('populateUpdateTable', e.text, 'Nome da Transportadora', '' + this.travel);
		this.populateFields();
	}

	resetInfo() {
		const categoryAnimals = this.fields.data.animais
			.filter((animal) => animal.quantidade > 0)
			.map((animal) => {
				const name = this.translateAnimalFieldName(animal.nome);
				return {
					animal: {
						value: name,
						text: name,
						max: animal.quantidade,
					},
					quantity: animal.quantidade,
				};
			});

		this.form.truckType = this.fields.truckType;
		this.form.dateInit = this.fields.dateInit;
		this.form.dateEnd = this.fields.dateEnd;
		this.form.freight = this.fields.freight;
		this.form.extra = this.fields.extra;
		this.form.categoryAnimals = categoryAnimals;
	}

	translateAnimalFieldName(animalFieldName) {
		switch (animalFieldName) {
			case 'Boi':
				return 'bois';
			case 'Vaca':
				return 'vacas';
			default:
				return animalFieldName;
		}
	}

	onSave() {
		const animalsInfo = {
			bois: { qty: 0, size: 0 },
			vacas: { qty: 0, size: 0 },
			novilhas: { qty: 0, size: 0 },
			bezerros: { qty: 0, size: 0 },
		};
		let sum = 0;
		for (const key in this.totalQuantity) {
			if (key !== 'categoria') {
				sum += this.totalQuantity[key];
			}
		}

		if (
			this.form.categoryAnimals[0].quantity > this.form.categoryAnimals[0].animal.max &&
			sum != 0
		) {
			this.$toast.warning('Alocar ' + sum + ' animais pendentes!');
		} else if (
			this.form.categoryAnimals[0].quantity < this.form.categoryAnimals[0].animal.max &&
			sum != 0
		) {
			this.$toast.warning(
				'Deve ser editado uma ou mais viagens reduzindo o total de animais alocados para respeitar o novo total.',
			);
		}

		const categories = this.form.categoryAnimals
			.map((category) => {
				const animal = category.animal?.value;
				if (!animal) return null;
				animalsInfo[animal].qty = category.quantity;
				animalsInfo[animal].size = parseFloat(this.animalsRows[1][animal]) * category.quantity;
				return animal[0].toUpperCase() + animal.slice(1);
			})
			.filter(Boolean);

		if (hasDuplicateValues(categories)) {
			return this.$toast.error('Viagem com categorias duplicadas');
		}

		// if (invalidValues) {
		// 	return this.$toast.error('A quantidade de animais inválida');
		// }

		const hasShippingCompany = this.fields.companies?.value;

		if (!hasShippingCompany) {
			return this.$toast.error('Por favor, selecione uma transportadora');
		}

		const params = {
			idViagem: this.travel,
			idTransportadora: this.fields.companies?.value,
			qtdBoi: animalsInfo.bois.qty,
			qtdVaca: animalsInfo.vacas.qty,
			qtdNovilho: animalsInfo.novilhas.qty,
			qtdBezerro: animalsInfo.bezerros.qty,
			roteiro: this.roadMap,
		};

		const { driver, truck, cart, carts, data } = this.fields;

		if (driver?.value) {
			params['idMotorista'] = driver.value;
		}

		if (truck?.value) {
			params['idCaminhao'] = truck.value;
		}

		if (cart?.value) {
			params['idCarretas'] = [cart.value];
		}
		if (carts.length) {
			params['idCarretas'] = carts.map((cart) => cart.cart?.value);
		}

		if (data.previsaoInicioViagem) {
			params['previsaoInicioViagem'] = data.previsaoInicioViagem;
		}

		if (data.previsaoFimViagem) {
			params['previsaoFimViagem'] = data.previsaoFimViagem;
		}

		const { truckType, dateInit, dateEnd } = this.form;

		if (truckType?.value) {
			params['tipoCaminhao'] = this.form.truckType.value;
		}
		if (dateInit) {
			params['previsaoInicioViagem'] = formatDateISO(dateInit);
		}
		if (dateEnd) {
			params['previsaoFimViagem'] = formatDateISO(dateEnd);
		}

		if (Object.keys(params).some((i) => !(params[i] || params[i] === 0))) {
			return this.$toast.error('Viagem com valores incompletos');
		}

		this.buttonBusy.saveButton = true;

		this.$store
			.dispatch(ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST, params)
			.then(() => {
				this.$toast.success('Viagem atualizada com sucesso!');
				this.$emit('success');
			})
			.catch(() => {
				this.$toast.error('Erro ao atualizar viagem!');
			})
			.finally(() => {
				this.buttonBusy.saveButton = false;
			});

		this.$emit('success');
	}

	addCart() {
		this.fields.carts.push({ cart: null });
	}

	removeCart(index) {
		this.fields.carts.splice(index, 1);
	}

	clearCart() {
		this.fields.carts[0].cart = null;
	}

	onEdit() {
		this.isEdit = !this.isEdit;
		if (!this.isEdit) this.resetInfo();
	}

	onOpen() {
		this.active = true;
	}

	onClose() {
		this.active = false;
	}

	setDriver() {
		this.fields.driver.text = this.fields.driver.nome;
		this.fields.driver.value = this.fields.driver.idParceiro;
	}

	cleanFields() {
		this.fields.driver = '';
		this.selectOptions.drivers = [];

		this.fields.truck = '';
		this.selectOptions.trucks = [];

		this.fields.cart = '';
		this.fields.availableCarts = [];
	}

	/* Watcher */
	@Watch('fields.companies')
	onChangeCompanyImmediate(oldValue, newValue) {
		this.setDriver();
		const hasChanged = newValue.value !== oldValue.value;
		if (hasChanged) {
			this.cleanFields();
		}
	}

	@Watch('form', { deep: true })
	onChangeForm() {
		const payload = {
			travelId: this.travel,
			...this.form,
		};

		this.$emit('updateForm', payload);
	}
}
