
import { Vue, Component, Prop } from 'vue-property-decorator';
import Label from '@/components/widgets/atoms/Label/Label.vue';
import DatePicker from '@/components/widgets/atoms/VueDatePicker/VueDatePicker.vue';

@Component({
	components: {
		Label,
		DatePicker,
	},
})
export default class FormDate extends Vue {
	/* Props */
	@Prop({ default: 'Label' })
	public label!: string;

	@Prop()
	public value!: string;

	@Prop({ default: 'input' })
	public name!: string;

	@Prop({ default: 'datetime' })
	public type!: string;

	@Prop({ default: 'default' })
	public variant!: string;

	@Prop()
	public placeholder!: string;

	@Prop({ default: 'DD/MM/YYYY HH:mm' })
	public format!: string;

	@Prop()
	public disableDate!: string;

	@Prop({ default: false })
	public disabled!: boolean;

	@Prop({ default: false })
	public required!: boolean;
}
