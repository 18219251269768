import transitOrders from './orders';
import transitNewOrder from './newOrder';
import transitFilters from './filters';
import transitTravels from './travels';
import transitRegisters from './registers';
import shippingCompany from './shippingCompany';

export default {
	transitOrders,
	transitNewOrder,
	transitTravels,
	transitFilters,
	transitRegisters,
	shippingCompany,
};
