export enum AvailableFilters {
	Date,
	Origin,
	Destiny,
	Tracking,
	Situation,
	Status,
	Driver,
	Truck,
	Cart,
	Travel,
	Order,
	OriginRancher,
	DriverDetachedForm,
	PedidoIsUpdated,
}
