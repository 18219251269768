export const TRANSIT_LOAD_TRAVEL_REQUEST = 'TRANSIT_LOAD_TRAVEL_REQUEST';
export const TRANSIT_LOAD_TRAVEL_SUCCESS = 'TRANSIT_LOAD_TRAVEL_SUCCESS';
export const TRANSIT_LOAD_TRAVEL_FAILED = 'TRANSIT_LOAD_TRAVEL_FAILED';

export const TRANSIT_TRAVEL_DETAIL_REQUEST = 'TRANSIT_TRAVEL_DETAIL_REQUEST';
export const TRANSIT_TRAVEL_DETAIL_SUCCESS = 'TRANSIT_TRAVEL_DETAIL_SUCCESS';
export const TRANSIT_TRAVEL_DETAIL_FAILED = 'TRANSIT_TRAVEL_DETAIL_FAILED';

export const TRANSIT_GET_COLUMNS = 'TRANSIT_GET_COLUMNS';
export const TRANSIT_GET_DOWNLOAD_FILE = 'TRANSIT_GET_DOWNLOAD_FILE';
export const TRANSIT_GET_DOWNLOAD_IMAGE = 'TRANSIT_GET_DOWNLOAD_IMAGE';
export const TRANSIT_GET_ACTIVITY_HISTORY = 'TRANSIT_GET_ACTIVITY_HISTORY';
export const TRANSIT_GET_RESUME = 'TRANSIT_GET_RESUME';
export const TRANSIT_GET_KM_OCCURRENCE = 'TRANSIT_GET_KM_OCCURRENCE';
export const TRANSIT_GET_ANIMAL_OCCURRENCE = 'TRANSIT_GET_ANIMAL_OCCURRENCE';
export const TRANSIT_GET_TRUCK_OCCURRENCE = 'TRANSIT_GET_TRUCK_OCCURRENCE';
export const TRANSIT_GET_MAP_PATH = 'TRANSIT_GET_MAP_PATH';
export const TRANSIT_GET_ACTUAL_LOCALIZATION = 'TRANSIT_GET_ACTUAL_LOCALIZATION';
export const TRANSIT_GET_PARTNERS = 'TRANSIT_GET_PARTNERS';

export const TRANSIT_GET_PARTNER_REQUEST = 'TRANSIT_GET_PARTNER_REQUEST';
export const TRANSIT_GET_PARTNER_SUCCESS = 'TRANSIT_GET_PARTNER_SUCCESS';
export const TRANSIT_GET_PARTNER_FAILED = 'TRANSIT_GET_PARTNER_FAILED';

export const ESCALATOR_TRAVEL_GET_DRIVERS = 'ESCALATOR_TRAVEL_GET_DRIVERS';
export const ESCALATOR_GET_TRUCKS = 'ESCALATOR_GET_TRUCKS';
export const ESCALATOR_GET_CARTS = 'ESCALATOR_GET_CARTS';

export const TRANSIT_PUT_COLUMNS = 'TRANSIT_PUT_COLUMNS';

export const TRANSIT_PUT_TRAVEL_DETAIL = 'TRANSIT_PUT_RAVEL_DETAIL';

export const TRANSIT_UPLOAD_FILE = 'TRANSIT_UPLOAD_FILE';
export const TRANSIT_POST_ACTIVITY_HISTORY = 'TRANSIT_POST_ACTIVITY_HISTORY';
export const TRANSIT_POST_KM_OBSERVATION = 'TRANSIT_POST_KM_OBSERVATION';
export const TRANSIT_POST_ANIMAL_BOARDING_OBSERVATION = 'TRANSIT_POST_ANIMAL_BOARDING_OBSERVATION';
export const TRANSIT_POST_ANIMAL_TRAVEL_OBSERVATION = 'TRANSIT_POST_ANIMAL_TRAVEL_OBSERVATION';
export const TRANSIT_POST_ANIMAL_LANDING_OBSERVATION = 'TRANSIT_POST_ANIMAL_LANDING_OBSERVATION';
export const TRANSIT_POST_TRUCK_OBSERVATION = 'TRANSIT_POST_TRUCK_OBSERVATION';
export const TRANSIT_POST_PROCESS_CTE = 'TRANSIT_POST_PROCESS_CTE';

export const SELECT_TRANSIT_TRAVEL_DETAIL = 'SELECT_TRANSIT_TRAVEL_DETAIL';
export const SELECT_TRANSIT_HISTORICAL = 'SELECT_TRANSIT_HISTORICAL';
export const SELECT_TRANSIT_MAP = 'SELECT_TRANSIT_MAP';
export const SELECT_TRANSIT_OCCURRENCE = 'SELECT_TRANSIT_OCCURRENCE';
export const SELECT_TRANSIT_RESUME = 'SELECT_TRANSIT_RESUME';

export const TRANSIT_TRAVEL_DETACHED_FORM_REQUEST = 'TRANSIT_TRAVEL_DETACHED_FORM_REQUEST';
export const SELECT_TRANSIT_TRAVEL_DETACHED_FORM = 'SELECT_TRANSIT_TRAVEL_DETACHED_FORM';

export const TRANSIT_TRAVEL_DETACHED_FORM_TRIP_REQUEST =
	'TRANSIT_TRAVEL_DETACHED_FORM_TRIP_REQUEST';
export const SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP = 'SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP';
export const TRANSIT_TRAVEL_POST_LINK_DETACHED_FORM = 'TRANSIT_TRAVEL_POST_LINK_DETACHED_FORM';
export const SELECT_TRANSIT_DRIVER_DETACHED_FORM = 'SELECT_TRANSIT_DRIVER_DETACHED_FORM';
