
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import Card from '@/components/widgets/molecules/Card/Card.vue';
import FormSelect from '@/components/widgets/molecules/FormSelect/FormSelect.vue';
import FormInput from '@/components/widgets/molecules/FormInput/FormInput.vue';
import PlusIcon from '@/components/icons/Plus.vue';
import LessIcon from '@/components/icons/Less.vue';

@Component({
	components: {
		Button,
		Card,
		FormSelect,
		FormInput,
		PlusIcon,
		LessIcon,
	},
})
export default class CategoryFields extends Vue {
	/* Props */
	@Prop({ required: true })
	public data!: Array<any>;

	@Prop({ required: true })
	public animals!: Array<any>;

	/* Computed */
	get copyAnimals() {
		return JSON.parse(JSON.stringify(this.animals));
	}

	get availableAnimals() {
		const animals = this.data
			.map((category) => {
				return category.animal?.value;
			})
			.filter(Boolean);
		return this.copyAnimals.filter((animal) => {
			return !animals.includes(animal.value);
		});
	}

	get validation() {
		return (
			!this.data.every((category) => category.animal && category.quantity) ||
			!(this.animals.length > this.data.length)
		);
	}
	/* Watcher */
	@Watch('data', { immediate: true })
	onDataChange(value) {
		if (value.length === 0) this.data.push({ animal: null, quantity: null });
	}

	/* Methods */
	addCategory() {
		if (!this.validation) {
			this.data.push({ animal: null, quantity: null });
		}
	}

	emitItem(e, label) {
		const quantidade = { text: e };
		this.$emit('populateItemRowTable', quantidade, label);
	}

	removeCategory(index) {
		this.data.splice(index, 1);
	}
}
