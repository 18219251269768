
import { Component, Vue, Watch } from 'vue-property-decorator';
import Filters from '../../organisms/Filter/Filter.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue';

import OrderModal from '../../organisms/Modals/Order/Order.vue';
import ScaleOrderModal from '../../organisms/Modals/ScaleOrder/ScaleOrder.vue';

import { AvailableFilters } from '@/models/filters/available-filters.interface';
import { formatDateHour, formatDateISO } from '@/shared/util/formatDate';
import formatMoney from '@/shared/util/formatMoney';
import formatNumber from '@/shared/util/formatNumber';
import { situationlabel } from '@/constants/operationalSituationLabels';
import { saveXLSX } from '@/helpers/ExportXLSX';

import {
	ESCALATOR_LOAD_ORDER_REQUEST,
	ESCALATOR_SELECT_ORDER,
	ESCALATOR_EXPORTXLSX,
  ESCALATOR_SCALE_ORDER,
} from '@/store/escalator/orders/actions';

@Component({
	components: {
		OrderModal,
		ScaleOrderModal,
		Table,
		Pagination,
		Filters,
	},
})
export default class TransitOrders extends Vue {
	/* Data */
	private availableFilters: any = AvailableFilters;

	private isLoaded: boolean = true;

	private rows: any = [];

	private totalPages: number = 0;

	private params: any = {
		page: 1,
		size: 16,
		dataInicio: '',
		dataFim: '',
		pecuaristaOrigemId: '',
		pecuaristaDestinoId: '',
		fazendaOrigemId: '',
		fazendaDestinoId: '',
		pedidoId: '',
		codigoSituacaoOperacional: '',
	};

	private header: any = [];

	private fixedHeader: any = [
		{ key: 'idPedido', label: 'Pedido Uboi' },
		{ key: 'codigoInterno', label: 'Código Interno' },
		{ key: 'pecuaristaOrigem', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaOrigem', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'pecuaristaDestino', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaDestino', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'qtdAnimais', label: 'Quantidade', thClass: 'dark-brown' },
		{ key: 'categoriaAnimais', label: 'Categoria', thClass: 'dark-brown' },
		{ key: 'peso', label: 'Peso Médio', thClass: 'dark-brown' },
		{ key: 'kmEntrePontos', label: 'Km Viagem' },
		//{ key: 'valorCotacao', label: 'Valor Total' },
		{ key: 'previsaoEmbarque', label: 'Embarque' },
		{ key: 'previsaoDesembarque', label: 'Desembarque' },
		{ key: 'situacaoOperacional', label: 'Status', thClass: 'dark-header' },
		{
			key: 'actions',
			label: 'Ações',
			thClass: 'gray-header',
			tdClass: 'td-action',
		},
	];

	/* Computed */
	get exportXLSX() {
		return this.$store.getters.hasOrderExportXlsx;
	}

	/* Watcher */
	@Watch('exportXLSX')
	onExportChange(value) {
		if (value) this.exportXLS();
	}

	/* LifeCycle */
	mounted() {
		this.getOrders(this.params);
	}

	/* Methods */
	submitFilter(filterDefinition: any): void {
		Object.keys(filterDefinition).forEach((index) => {
			const numberIndex = +index;
			switch (numberIndex) {
				case this.availableFilters.Date:
					this.params.dataInicio = formatDateISO(filterDefinition[numberIndex].startDateSelected);
					this.params.dataFim = formatDateISO(filterDefinition[numberIndex].endDateSelected);
					break;
				case this.availableFilters.Origin:
					this.params.pecuaristaOrigemId =
						filterDefinition[numberIndex].rancherSelected?.value || '';
					this.params.fazendaOrigemId = filterDefinition[numberIndex].farmSelected?.value || '';
					break;
				case this.availableFilters.Destiny:
					this.params.pecuaristaDestinoId =
						filterDefinition[numberIndex].rancherSelected?.value || '';
					this.params.fazendaDestinoId = filterDefinition[numberIndex].farmSelected?.value || '';
					break;
				case this.availableFilters.Situation:
					this.params.codigoSituacaoOperacional =
						filterDefinition[index].situationSelected?.value || '';
					break;
				case this.availableFilters.Order:
					this.params.pedidoId = filterDefinition[index].orderSelected;
					break;
			}
		});
		this.params.page = 1;
		this.getOrders(this.params);
	}

	getOrders(params: any) {
		(this.isLoaded = false),
			this.$store
				.dispatch(ESCALATOR_LOAD_ORDER_REQUEST, params)
				.then(({ totalCount, results }) => {
					if (results.length === 0) return this.$toast.error('Nenhum pedido encontrado');
					this.header = this.fixedHeader;
					this.totalPages = totalCount;
					this.rows = results.map((i) => {
						const situation = i?.situacaoOperacional
							? situationlabel[i.situacaoOperacional]?.label || i.situacaoOperacional
							: '';
						delete i.situacaoOperacional;
						return {
							...i,
							situacaoOperacional: situation,
							previsaoEmbarque: formatDateHour(i.previsaoEmbarque),
							previsaoDesembarque: formatDateHour(i.previsaoDesembarque),
							//valorCotacao: formatMoney(i.valorCotacao),
							peso: formatNumber(i.peso),
							kmEntrePontos: formatNumber(i.kmEntrePontos),
							codigoInterno: i.codigoInterno,

							actions: {
								id: i.idPedido,
								buttons: i.pedidoEscalado ? [] : ['scale'],
							},
						};
					});
				})
				.catch(() => {
					this.$toast.error('Erro ao carregar pedidos');
				})
				.finally(() => {
					this.isLoaded = true;
				});
	}

	message(message, variant, reload = false) {
		if (reload) this.getOrders(this.params);
		this.$nextTick(() => {
			this.$toast.open({
				message,
				type: variant,
			});
		});
	}

	changePage(page) {
		this.params.page = page;
		this.getOrders(this.params);
	}

	changeSize(size) {
		this.params.size = size;
		this.params.page = 1;
		this.getOrders(this.params);
	}

	exportXLS() {
		const params = { ...this.params };
		params.size = this.totalPages;
		let body = [];
		const header = this.header.map((item) => {
			switch (item?.key) {
				case 'pecuaristaOrigem':
					return { label: 'Pecuarista Origem', value: item?.key };
				case 'fazendaOrigem':
					return { label: 'Fazenda Origem', value: item?.key };
				case 'pecuaristaDestino':
					return { label: 'Pecuarista Destino', value: item?.key };
				case 'fazendaDestino':
					return { label: 'Fazenda Destino', value: item?.key };
				default:
					return { label: item?.label || item?.key, value: item?.key };
			}
		});
		header.pop();
		this.$store
			.dispatch(ESCALATOR_LOAD_ORDER_REQUEST, params)
			.then(({ results }) => {
				if (results.length == 0) {
					return;
				}
				body = results.map((i) => {
					const situation = i?.situacaoOperacional
						? situationlabel[i.situacaoOperacional]?.label || i.situacaoOperacional
						: '';
					delete i.situacaoOperacional;
					return {
						...i,
						situacaoOperacional: situation,
						previsaoEmbarque: formatDateHour(i.previsaoEmbarque),
						previsaoDesembarque: formatDateHour(i.previsaoDesembarque),
					};
				});
			})
			.catch((e) => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.$store.dispatch(ESCALATOR_EXPORTXLSX, null);
				saveXLSX(header, body, 'tabela', 'Lista-pedidos');
			});
	}

	openDetail(pedido) {
		this.$store.dispatch(ESCALATOR_SELECT_ORDER, pedido.id);
	}

	openScale(pedido) {
		this.$store.dispatch(ESCALATOR_SCALE_ORDER, pedido.id);
	}
}
