export const ESCALATOR_TRUCK_TYPES_REQUEST = 'ESCALATOR_TRUCK_TYPES_REQUEST';
export const ESCALATOR_SHIPPING_COMPANY_REQUEST = 'ESCALATOR_SHIPPING_COMPANY_REQUEST';

export const ESCALATOR_LOAD_ORDER_REQUEST = 'ESCALATOR_LOAD_ORDER_REQUEST';
export const ESCALATOR_LOAD_ORDER_SUCCESS = 'ESCALATOR_LOAD_ORDER_SUCCESS';
export const ESCALATOR_LOAD_ORDER_FAILED = 'ESCALATOR_LOAD_ORDER_FAILED';

export const ESCALATOR_ORIGINATION_LOAD_ORDER_REQUEST = 'ESCALATOR_ORIGINATION_LOAD_ORDER_REQUEST';
export const ESCALATOR_ORIGINATION_LOAD_ORDER_SUCCESS = 'ESCALATOR_ORIGINATION_LOAD_ORDER_SUCCESS';
export const ESCALATOR_ORIGINATION_LOAD_ORDER_FAILED = 'ESCALATOR_ORIGINATION_LOAD_ORDER_FAILED';

export const ESCALATOR_LOAD_CONTRACTS_REQUEST = 'ESCALATOR_LOAD_CONTRACTS_REQUEST';
export const ESCALATOR_LOAD_CONTRACTS_SUCCESS = 'ESCALATOR_LOAD_CONTRACTS_SUCCESS';
export const ESCALATOR_LOAD_CONTRACTS_FAILED = 'ESCALATOR_LOAD_CONTRACTS_FAILED';

export const ESCALATOR_ORDER_DETAIL_REQUEST = 'ESCALATOR_ORDER_DETAIL_REQUEST';
export const TRANSIT_ORDER_DETAIL_SUCCESS = 'TRANSIT_ORDER_DETAIL_SUCCESS';
export const TRANSIT_ORDER_DETAIL_FAILED = 'TRANSIT_ORDER_DETAIL_FAILED';

export const ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST =
	'ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST';

export const ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST_2 =
	'ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST_2';

export const ESCALATOR_ORIGINATION_ORDER_DETAIL_SUCCESS =
	'ESCALATOR_ORIGINATION_ORDER_DETAIL_SUCCESS';
export const ESCALATOR_ORIGINATION_ORDER_DETAIL_FAILED =
	'ESCALATOR_ORIGINATION_ORDER_DETAIL_FAILED';

export const ESCALATOR_ORIGINATION_DELETE_TRAVEL_REQUEST =
	'ESCALATOR_ORIGINATION_DELETE_TRAVEL_REQUEST';

export const ESCALATOR_CONTRACT_DETAIL_REQUEST = 'ESCALATOR_CONTRACT_DETAIL_REQUEST';
export const ESCALATOR_CONTRACT_DETAIL_SUCCESS = 'ESCALATOR_CONTRACT_DETAIL_SUCCESS';
export const ESCALATOR_CONTRACT_DETAIL_FAILED = 'ESCALATOR_CONTRACT_DETAIL_FAILED';

export const ESCALATOR_POST_PROPOSAL = 'ESCALATOR_POST_PROPOSAL';
export const TRANSIT_POST_CONTRACT = 'TRANSIT_POST_CONTRACT';

export const ESCALATOR_PUT_TRAVEL_REQUEST = 'ESCALATOR_PUT_TRAVEL_REQUEST';
export const ESCALATOR_PUT_TRAVEL_SUCCESS = 'ESCALATOR_PUT_TRAVEL_SUCCESS';
export const ESCALATOR_PUT_TRAVEL_FAILED = 'ESCALATOR_PUT_TRAVEL_FAILED';

export const ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST =
	'ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST';
export const ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST_2 =
	'ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST_2';

export const ESCALATOR_ORIGINATION_POST_TRAVEL_SUCCESS =
	'ESCALATOR_ORIGINATION_POST_TRAVEL_SUCCESS';
export const ESCALATOR_ORIGINATION_POST_TRAVEL_FAILED = 'ESCALATOR_ORIGINATION_POST_TRAVEL_FAILED';

export const ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST = 'ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST';
export const ESCALATOR_ORIGINATION_PUT_TRAVEL_SUCCESS = 'ESCALATOR_ORIGINATION_PUT_TRAVEL_SUCCESS';
export const ESCALATOR_ORIGINATION_PUT_TRAVEL_FAILED = 'ESCALATOR_ORIGINATION_PUT_TRAVEL_FAILED';

export const TRANSIT_PUT_CONTRACT = 'TRANSIT_PUT_CONTRACT';

export const TRANSIT_DELETE_TRAVEL = 'TRANSIT_DELETE_TRAVEL';

export const ESCALATOR_SELECT_ORDER = 'ESCALATOR_SELECT_ORDER';
export const ESCALATOR_SELECT_CONTRACT = 'ESCALATOR_SELECT_CONTRACT';
export const ESCALATOR_SELECT_ORIGINATION = 'ESCALATOR_SELECT_ORIGINATION';

export const ESCALATOR_ORIGINATION_DETAIL_REQUEST = 'ESCALATOR_ORIGINATION_DETAIL_REQUEST';
export const ESCALATOR_ORIGINATION_DETAIL_SUCCESS = 'ESCALATOR_ORIGINATION_DETAIL_SUCCESS';
export const ESCALATOR_ORIGINATION_DETAIL_FAILED = 'ESCALATOR_ORIGINATION_DETAIL_FAILED';

export const ESCALATOR_POST_TRAVEL_REQUEST = 'ESCALATOR_POST_TRAVEL_REQUEST';
export const ESCALATOR_POST_TRAVEL_SUCCESS = 'ESCALATOR_POST_TRAVEL_SUCCESS';
export const ESCALATOR_POST_TRAVEL_FAILED = 'ESCALATOR_POST_TRAVEL_FAILED';

export const ESCALATOR_CANCEL_ORDER_REQUEST = 'ESCALATOR_CANCEL_ORDER_REQUEST';
export const ESCALATOR_CANCEL_ORDER_FAILED = 'ESCALATOR_CANCEL_ORDER_FAILED';
export const ESCALATOR_CANCEL_ORDER_SUCCESS = 'ESCALATOR_CANCEL_ORDER_SUCCESS';

export const ESCALATOR_SCALE_ORDER = 'ESCALATOR_SCALE_ORDER';
export const ESCALATOR_SCALE_TO_JBS = 'ESCALATOR_SCALE_TO_JBS';
export const ESCALATOR_SCALE_TO_TRANSIT_FACILITIES = 'ESCALATOR_SCALE_TO_TRANSIT_FACILITIES';

export const TRANSIT_RELOAD_TRAVEL = 'TRANSIT_RELOAD_TRAVEL';

export const ESCALATOR_EXPORTXLSX = 'ESCALATOR_EXPORTXLSX';

export const ESCALATOR_SET_ROADMAP = 'ESCALATOR_SET_ROADMAP';
export const ESCALATOR_ORIGINATION_SET_POST_TRAVEL = 'ESCALATOR_ORIGINATION_SET_POST_TRAVEL';

export const ESCALATOR_DELETED_TRAVEL = 'ESCALATOR_DELETED_TRAVEL';
