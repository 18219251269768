/* Auth Actions for Store */
export const ESCALATOR_ORDER_ORIGIN_RANCHER_REQUEST = 'ESCALATOR_ORDER_ORIGIN_RANCHER_REQUEST';
export const TRANSIT_ORDER_DESTINY_RANCHER_REQUEST = 'TRANSIT_ORDER_DESTINY_RANCHER_REQUEST';
export const TRANSIT_TRAVEL_ORIGIN_RANCHER_REQUEST = 'TRANSIT_TRAVEL_ORIGIN_RANCHER_REQUEST';
export const TRANSIT_TRAVEL_DESTINY_RANCHER_REQUEST = 'TRANSIT_TRAVEL_DESTINY_RANCHER_REQUEST';

export const ESCALATOR_ORDER_ORIGIN_FARM_REQUEST = 'ESCALATOR_ORDER_ORIGIN_FARM_REQUEST';
export const TRANSIT_ORDER_DESTINY_FARM_REQUEST = 'TRANSIT_ORDER_DESTINY_FARM_REQUEST';
export const TRANSIT_TRAVEL_ORIGIN_FARM_REQUEST = 'TRANSIT_TRAVEL_ORIGIN_FARM_REQUEST';
export const TRANSIT_TRAVEL_DESTINY_FARM_REQUEST = 'TRANSIT_TRAVEL_DESTINY_FARM_REQUEST';

export const ESCALATOR_TRACKING_TYPES_REQUEST = 'ESCALATOR_TRACKING_TYPES_REQUEST';
export const ESCALATOR_OPERATIONAL_SITUATION_REQUEST = 'ESCALATOR_OPERATIONAL_SITUATION_REQUEST';
export const ESCALATOR_STATUS_PROPOSAL_REQUEST = 'ESCALATOR_STATUS_PROPOSAL_REQUEST';
export const TRANSIT_DRIVERS_REQUEST = 'TRANSIT_DRIVERS_REQUEST';
export const TRANSIT_PARTNER_MAP_REQUEST = 'TRANSIT_PARTNER_MAP_REQUEST';
export const TRANSIT_DRIVER_MAP_REQUEST = 'TRANSIT_DRIVER_MAP_REQUEST';

export const TRANSIT_TRAVEL_DRIVER_DETACHED_FORM_REQUEST =
	'TRANSIT_TRAVEL_DRIVER_DETACHED_FORM_REQUEST';
