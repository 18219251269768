
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import IconButtonsGroup from '@/components/widgets/molecules/IconButtonsGroup/IconButtonsGroup.vue';
import InputRate from '@/components/widgets/atoms/InputRate/InputRate.vue';

@Component({
	components: {
		IconButtonsGroup,
		InputRate,
	},
})
export default class Table extends Vue {
	/* Props */
	@Prop({ required: true })
	public header!: any;

	@Prop({ required: true })
	public rows!: any;

	@Prop({ default: true })
	public isLoaded!: boolean;

	@Prop({ default: true })
	public responsive!: boolean;

	@Prop({ default: false })
	public stickHeader!: string;

	@Prop({ default: false })
	public extraHeader!: string;

	@Prop({ default: false })
	public occurrence!: string;

	@Prop({ default: false })
	public isTrasitOrder!: string;

	@Prop({ default: false })
	public isPermission!: boolean;

	@Prop({ default: false })
	public customExtraHeader!: string;

	@Prop({ default: 0 })
	public customExtraColspan!: number;

	@Prop({ default: false })
	public hasTableShadow!: boolean;

	@Prop({ default: false })
	public hasMinHeader!: boolean;

	@Prop({ default: false })
	public noAlternatingRowColor!: boolean;

	@Prop({ default: true })
	public hasScrollTop!: boolean;

	/* Data */
	private hasScroll: boolean = false;

	private scrollWidth: number = 0;

	private clientWidth: number = 0;

	public hoverClass: string = '';

	public spacing: boolean = false;

	/* Watcher */
	@Watch('isLoaded')
	onLoaded(value) {
		if (value) this.loadScrollValues();
	}

	/* Lifecicle */
	mounted() {
		const mainScroll: any = this.$el;
		mainScroll.addEventListener('scroll', this.mainScroll);
		window.addEventListener('resize', this.loadScrollValues);
	}

	destroy() {
		const mainScroll: any = this.$el;
		mainScroll.removeEventListener('scroll', this.mainScroll);
		window.removeEventListener('resize', this.loadScrollValues);
	}

	/* Methods */
	loadScrollValues() {
		this.$nextTick(() => {
			this.scrollWidth = this.$el.scrollWidth;
			this.clientWidth = this.$el.clientWidth;
			this.hasScroll = this.$el.scrollWidth > this.$el.clientWidth;
		});
	}

	mainScroll(e) {
		this.$refs.topScroll = e.target.scrollLeft;
	}

	scrollTop(e) {
		this.$el.scrollLeft = e.target.scrollLeft;
	}

	rowClass(item) {
		if (item?.ativo && !item.ativo?.status) return 'table-body grey-row';
		if (item?.detalhes && item.detalhes?.status) return 'table-body selected-row';
		return 'table-body';
	}

	openAction(action, values) {
		this.$emit(action, values);
	}

	scrollHover() {
		this.hoverClass = 'mouseHover';
		this.spacing = true;
	}

	scrollLeave() {
		this.hoverClass = '';
		this.spacing = false;
	}
}
