
import { Component, Vue } from 'vue-property-decorator';
import WarningIcon from '@/components/icons/Warning.vue';
import Form from '@/components/pages/recoverPassword/organisms/ResetPasswordForm/ResetPasswordForm.vue';

import { CONSTANTS } from '@/constants/constants';

@Component({
	components: {
		WarningIcon,
		Form,
	},
})
export default class RecoverEscalador extends Vue {
	/* Computed */
	get routerName() {
		return this.$route.meta?.route;
	}

	get version() {
		return CONSTANTS.SYSTEM_VERSION;
	}
}
