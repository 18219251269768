
import { Vue, Component, Prop } from 'vue-property-decorator';
import IconButton from '../../atoms/IconButton/IconButton.vue';

@Component({
	components: {
		IconButton,
	},
})
export default class IconButtonsGroup extends Vue {
	/* Props */
	@Prop({ default: () => [] })
	public buttons!: Array<any>;

	/* Computed */
	get configButtons() {
		return this.buttons.map((button) => {
			if (typeof button === 'string') return { icon: button };
			return button;
		});
	}
}
