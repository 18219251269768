
import { Vue, Component, Prop } from 'vue-property-decorator';

import getMaskDefinition from '@/shared/util/masks';

@Component({})
export default class Input extends Vue {
	/* Props */
	@Prop()
	public value!: string;

	@Prop({ default: 'input' })
	public name!: string;

	@Prop({ default: 'text' })
	public type!: string;

	@Prop({ default: 'default' })
	public variant!: string;

	@Prop()
	public mask!: string | null | undefined;

	@Prop({ default: false })
	public disabled!: boolean;

	@Prop({ default: false })
	public required!: boolean;

	@Prop()
	public placeholder!: string | undefined;

	/* Computed */
	get variantClass() {
		return `input-mask--${this.variant}`;
	}

	/* Methods */
	maskDefinition(value) {
		return getMaskDefinition(value);
	}
}
