
import { Component, Vue } from 'vue-property-decorator';

import { AUTH_LOGOUT } from '@/store/auth/actions';

@Component({})
export default class Login extends Vue {
	created() {
		this.$store.dispatch(AUTH_LOGOUT);
	}
}
