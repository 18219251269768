
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';

import { CONSTANTS } from '@/constants/constants';

@Component({
	components: {
		VueRecaptcha,
	},
})
export default class ReCaptcha extends Vue {
	/* Data */
	private isShake: boolean = false;

	/* Props */
	@Prop({ default: false })
	public shake!: boolean;

	/* Computed */
	get reCaptchaKey() {
		return CONSTANTS.RECAPTCHA_SITE_KEY;
	}

	/* Watcher */
	@Watch('shake')
	onShakeChange() {
		clearTimeout();
		this.isShake = true;
		setTimeout(() => {
			this.isShake = false;
		}, 1000);
	}

	/* Lifecycle */
	mounted() {
		this.$emit('verify', false);
	}

	/* Methods */
	verifyMethod(response) {
		this.$emit('verify', !!response);
	}

	expiredMethod() {
		this.$emit('verify', false);
	}
}
