
import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Multiselect from '@/components/widgets/atoms/Multiselect/Multiselect.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';

import { formatDateHour } from '@/shared/util/formatDate';
import formatMoney from '@/shared/util/formatMoney';
import formatNumber from '@/shared/util/formatNumber';

import {
	ESCALATOR_ORDER_DETAIL_REQUEST,
	ESCALATOR_POST_PROPOSAL,
	ESCALATOR_SELECT_ORDER,
} from '@/store/escalator/orders/actions';

@Component({
	components: {
		Modal,
		Table,
		Multiselect,
		ButtonCustom,
	},
})
export default class OrderModal extends Vue {
	/* Data */
	private orderHeader: any = [
		{ key: 'idPedido', label: 'Pedido Uboi' },
		{ key: 'codigoInterno', label: 'Código Interno' },
		{ key: 'pecuaristaOrigem', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaOrigem', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'pecuaristaDestino', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaDestino', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'kmViagem', label: 'KM Viagem' },
		{ key: 'valorTotal', label: 'Valor Total' },
		{ key: 'previsaoEmbarque', label: 'Embarque Estimado' },
		{ key: 'previsaoDesembarque', label: 'Desembarque Estimado' },
	];

	private orderRows: any = [];

	private animalsHeader: any = [
		{ key: 'categoria', label: 'Categoria', tdClass: 'brown-td' },
		{ key: 'bois', label: 'Bois', thClass: 'light-gray-header text-bold' },
		{ key: 'vacas', label: 'Vacas', thClass: 'light-gray-header text-bold' },
		{
			key: 'novilhas',
			label: 'Novilhas',
			thClass: 'light-gray-header text-bold',
		},
		{
			key: 'bezerros',
			label: 'Bezerros',
			thClass: 'light-gray-header text-bold',
		},
	];

	private animalsRows: any = [];

	private busy: boolean = false;

	/* Computed */
	get showModal() {
		return this.$store.getters.hasSelectedTransitOrder;
	}
	get selectedOrder() {
		return this.$store.getters.getSelectedTransitOrder;
	}
	get isLoading() {
		return this.$store.getters.isTransitOrderDetailLoading;
	}

	/* Methods */
	resetInfo() {
		this.animalsRows = [
			{
				categoria: 'Quantidade',
				bois: 0,
				vacas: 0,
				novilhas: 0,
				bezerros: 0,
			},
			{ categoria: 'Peso Médio', bois: 0, vacas: 0, novilhas: 0, bezerros: 0 },
		];
		this.$store.dispatch(ESCALATOR_SELECT_ORDER, null);
	}

	initInfo() {
		this.getInfo();
	}

	getInfo() {
		this.$store
			.dispatch(ESCALATOR_ORDER_DETAIL_REQUEST, this.selectedOrder)
			.then((response) => {
				this.orderRows = [
					{
						...response,
						valorTotal: formatMoney(response.valorTotal),
						previsaoEmbarque: formatDateHour(response.previsaoEmbarque),
						previsaoDesembarque: formatDateHour(response.previsaoDesembarque),
					},
				];
				const { animais } = response;
				const animalsRows = [
					{
						categoria: 'Quantidade',
						bois: animais?.qtdBoi || 0,
						vacas: animais?.qtdVaca || 0,
						novilhas: animais?.qtdNovilho || 0,
						bezerros: animais?.qtdBezerro || 0,
					},
					{
						categoria: 'Peso Médio',
						bois: formatNumber(animais?.pesoMedioBoi) || 0,
						vacas: formatNumber(animais?.pesoMedioVaca) || 0,
						novilhas: formatNumber(animais?.pesoMedioNovilho) || 0,
						bezerros: formatNumber(animais?.pesoMedioBezerro) || 0,
					},
				];

				this.animalsRows = animalsRows;

				delete response.viagens;
				delete response.animais;
				delete response.contratoGerado;
				delete response.tipo;
			})
			.catch(() => {
				this.$emit('message', 'Erro ao carregar pedido', 'error');
			});
	}

	sendProposal() {
		this.busy = true;
		this.$store
			.dispatch(ESCALATOR_POST_PROPOSAL, this.selectedOrder)
			.then(() => {
				this.$emit('message', 'Proposta de contrato criada com sucesso', 'success', true);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao criar proposta de contrato', 'error', true);
			})
			.finally(() => {
				this.busy = false;
				this.close();
			});
	}

	close() {
		this.$bvModal.hide('TransitOrderModal');
	}
}
