/* Imports */
import RegisterState from '@/models/TransitFacility/register-state/register-state.interface';
import api from '../../../shared/util/api';

/* Actions */
import {
	TRANSIT_REGISTER_GET_DRIVERS,
	TRANSIT_REGISTER_GET_CARTS,
	TRANSIT_REGISTER_GET_TRUCKS,
	TRANSIT_REGISTER_GET_SKETCH_DRIVER,
	TRANSIT_REGISTER_GET_SKETCH_CART,
	TRANSIT_REGISTER_GET_SKETCH_TRUCK,
	TRANSIT_REGISTER_GET_SUCCESS,
	TRANSIT_REGISTER_GET_FAILED,
	TRANSIT_REGISTER_POST_DRIVER,
	TRANSIT_REGISTER_POST_TRUCK,
	TRANSIT_REGISTER_POST_CART,
	TRANSIT_REGISTER_PUT_DRIVER,
	TRANSIT_REGISTER_PUT_CART,
	TRANSIT_REGISTER_PUT_TRUCK,
	SELECT_REGISTER_DRIVER,
	SELECT_REGISTER_CART,
	SELECT_REGISTER_TRUCK,
} from './actions';

const { http } = api.getInstance();

/* Initial AuthState object */
const state: RegisterState = {
	registerStatus: '',
	selectedRegisterDriver: null,
	selectedRegisterTruck: null,
	selectedRegisterCart: null,
};

/* Vuex Auth Getters */
const getters = {
	isTransitRegisterLoading: (state: RegisterState): string => state.registerStatus,
	getRegisterDriver: (state: RegisterState) => state.selectedRegisterDriver,
	hasSelectedRegisterDriver: (state: RegisterState): boolean =>
		state.selectedRegisterDriver !== null,
	getRegisterTruck: (state: RegisterState) => state.selectedRegisterTruck,
	hasSelectedRegisterTruck: (state: RegisterState): boolean => state.selectedRegisterTruck !== null,
	getRegisterCart: (state: RegisterState) => state.selectedRegisterCart,
	hasSelectedRegisterCart: (state: RegisterState): boolean => state.selectedRegisterCart !== null,
};

/* Vuex Auth Mutations */
const mutations = {
	[TRANSIT_REGISTER_GET_DRIVERS]: (state: RegisterState) => {
		state.registerStatus = 'loading';
	},
	[TRANSIT_REGISTER_GET_CARTS]: (state: RegisterState) => {
		state.registerStatus = 'loading';
	},
	[TRANSIT_REGISTER_GET_TRUCKS]: (state: RegisterState) => {
		state.registerStatus = 'loading';
	},
	[TRANSIT_REGISTER_GET_SUCCESS]: (state: RegisterState) => {
		state.registerStatus = 'success';
	},
	[TRANSIT_REGISTER_GET_FAILED]: (state: RegisterState) => {
		state.registerStatus = 'error';
	},
	[SELECT_REGISTER_DRIVER]: (state, driver) => {
		state.selectedRegisterDriver = driver;
	},
	[SELECT_REGISTER_TRUCK]: (state, truck) => {
		state.selectedRegisterTruck = truck;
	},
	[SELECT_REGISTER_CART]: (state, cart) => {
		state.selectedRegisterCart = cart;
	},
};

/* Vuex Auth Actions */
const actions = {
	[TRANSIT_REGISTER_GET_DRIVERS]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { page, size } = requestData;
			commit(TRANSIT_REGISTER_GET_DRIVERS);
			http({
				method: 'get',
				url: `/escalador/jbscadastro/motorista?page=${page}&size=${size}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_REGISTER_GET_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(TRANSIT_REGISTER_GET_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_GET_CARTS]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { page, size } = requestData;
			commit(TRANSIT_REGISTER_GET_CARTS);
			http({
				method: 'get',
				url: `/escalador/jbscadastro/carreta?page=${page}&size=${size}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_REGISTER_GET_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(TRANSIT_REGISTER_GET_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_GET_TRUCKS]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { page, size } = requestData;
			commit(TRANSIT_REGISTER_GET_TRUCKS);
			http({
				method: 'get',
				url: `/escalador/jbscadastro/caminhao?page=${page}&size=${size}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_REGISTER_GET_SUCCESS, data);
					resolve(data);
				})
				.catch((error) => {
					commit(TRANSIT_REGISTER_GET_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_GET_SKETCH_DRIVER]: ({ commit }: any, idDriver: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/escalador/jbscadastro/motorista/${idDriver}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_GET_SKETCH_CART]: ({ commit }: any, idCart: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/escalador/jbscadastro/carreta/${idCart}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_GET_SKETCH_TRUCK]: ({ commit }: any, idTruck: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/escalador/jbscadastro/caminhao/${idTruck}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_POST_DRIVER]: ({ commit }: any, formData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/escalador/jbscadastro/motorista`,
				data: formData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_POST_CART]: ({ commit }: any, formData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/escalador/jbscadastro/carreta`,
				data: formData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_POST_TRUCK]: ({ commit }: any, formData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'post',
				url: `/escalador/jbscadastro/caminhao`,
				data: formData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_PUT_DRIVER]: ({ commit }: any, formData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'put',
				url: `/escalador/jbscadastro/motorista`,
				data: formData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_PUT_CART]: ({ commit }: any, formData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'put',
				url: `/escalador/jbscadastro/carreta`,
				data: formData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[TRANSIT_REGISTER_PUT_TRUCK]: ({ commit }: any, formData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'put',
				url: `/escalador/jbscadastro/caminhao`,
				data: formData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	[SELECT_REGISTER_DRIVER]: ({ commit }, driver) => {
		commit(SELECT_REGISTER_DRIVER, driver);
	},
	[SELECT_REGISTER_TRUCK]: ({ commit }, truck) => {
		commit(SELECT_REGISTER_TRUCK, truck);
	},
	[SELECT_REGISTER_CART]: ({ commit }, cart) => {
		commit(SELECT_REGISTER_CART, cart);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
