/* Imports */
import OrderState from '@/models/TransitFacility/new-order-state/new-order-state.interface';
import api from '@/shared/util/api';

const { http } = api.getInstance();
const URL = '/escalador/jbspedido/lancamento';

/* Actions */
import {
	GET_TRANSIT_PARTNER_REQUEST,
	GET_TRANSIT_PARTNER_SUCCESS,
	GET_TRANSIT_PARTNER_FAILED,
	GET_TRANSIT_ORIGIN_PARTNER_REQUEST,
	GET_TRANSIT_ORIGIN_PARTNER_SUCCESS,
	GET_TRANSIT_ORIGIN_PARTNER_FAILED,
	GET_TRANSIT_ORIGIN_FARM_REQUEST,
	GET_TRANSIT_ORIGIN_FARM_SUCCESS,
	GET_TRANSIT_ORIGIN_FARM_FAILED,
	GET_TRANSIT_DESTINY_PARTNER_REQUEST,
	GET_TRANSIT_DESTINY_PARTNER_SUCCESS,
	GET_TRANSIT_DESTINY_PARTNER_FAILED,
	GET_TRANSIT_DESTINY_FARM_REQUEST,
	GET_TRANSIT_DESTINY_FARM_SUCCESS,
	GET_TRANSIT_DESTINY_FARM_FAILED,
	POST_TRANSIT_NEW_ORDER,
} from './actions';

/* Initial AuthState object */
const state: OrderState = {
	partnerStatus: '',
	originPartnerStatus: '',
	originFarmStatus: '',
	destinyPartnerStatus: '',
	destinyFarmStatus: '',
};

/* Vuex Auth Getters */
const getters = {
	isTPartnerLoading: (state: OrderState): boolean => state.partnerStatus === 'loading',
	isTOriginPartnerLoading: (state: OrderState): boolean => state.originPartnerStatus === 'loading',
	isTOriginFarmLoading: (state: OrderState): boolean => state.originFarmStatus === 'loading',
	isTDestinyPartnerLoading: (state: OrderState): boolean =>
		state.destinyPartnerStatus === 'loading',
	isTDestinyFarmLoading: (state: OrderState): boolean => state.destinyFarmStatus === 'loading',
};

/* Vuex Auth Mutations */
const mutations = {
	[GET_TRANSIT_PARTNER_REQUEST]: (state: OrderState) => (state.partnerStatus = 'loading'),
	[GET_TRANSIT_PARTNER_SUCCESS]: (state: OrderState) => (state.partnerStatus = 'success'),
	[GET_TRANSIT_PARTNER_FAILED]: (state: OrderState) => (state.partnerStatus = 'error'),
	[GET_TRANSIT_ORIGIN_PARTNER_REQUEST]: (state: OrderState) =>
		(state.originPartnerStatus = 'loading'),
	[GET_TRANSIT_ORIGIN_PARTNER_SUCCESS]: (state: OrderState) =>
		(state.originPartnerStatus = 'success'),
	[GET_TRANSIT_ORIGIN_PARTNER_FAILED]: (state: OrderState) => (state.originPartnerStatus = 'error'),
	[GET_TRANSIT_ORIGIN_FARM_REQUEST]: (state: OrderState) => (state.originFarmStatus = 'loading'),
	[GET_TRANSIT_ORIGIN_FARM_SUCCESS]: (state: OrderState) => (state.originFarmStatus = 'success'),
	[GET_TRANSIT_ORIGIN_FARM_FAILED]: (state: OrderState) => (state.originFarmStatus = 'error'),
	[GET_TRANSIT_DESTINY_PARTNER_REQUEST]: (state: OrderState) =>
		(state.destinyPartnerStatus = 'loading'),
	[GET_TRANSIT_DESTINY_PARTNER_SUCCESS]: (state: OrderState) =>
		(state.destinyPartnerStatus = 'success'),
	[GET_TRANSIT_DESTINY_PARTNER_FAILED]: (state: OrderState) =>
		(state.destinyPartnerStatus = 'error'),
	[GET_TRANSIT_DESTINY_FARM_REQUEST]: (state: OrderState) => (state.destinyFarmStatus = 'loading'),
	[GET_TRANSIT_DESTINY_FARM_SUCCESS]: (state: OrderState) => (state.destinyFarmStatus = 'success'),
	[GET_TRANSIT_DESTINY_FARM_FAILED]: (state: OrderState) => (state.destinyFarmStatus = 'error'),
};

/* Vuex Auth Actions */
const actions = {
	[GET_TRANSIT_PARTNER_REQUEST]: ({ commit }: any, params: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${1}`, `size=${20}`];
			if (params.search) queryParams.push(`name=${params.search}`);
			commit(GET_TRANSIT_PARTNER_REQUEST);
			http({
				method: 'GET',
				url: `${URL}/parceiro/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(GET_TRANSIT_PARTNER_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(GET_TRANSIT_PARTNER_FAILED);
					reject(error);
				});
		});
	},
	[GET_TRANSIT_ORIGIN_PARTNER_REQUEST]: ({ commit }: any, params: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${1}`, `size=${20}`];
			if (params.search) queryParams.push(`name=${params.search}`);
			commit(GET_TRANSIT_ORIGIN_PARTNER_REQUEST);
			http({
				method: 'GET',
				url: `${URL}/parceiro/origem/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(GET_TRANSIT_ORIGIN_PARTNER_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(GET_TRANSIT_ORIGIN_PARTNER_FAILED);
					reject(error);
				});
		});
	},
	[GET_TRANSIT_ORIGIN_FARM_REQUEST]: ({ commit }: any, id: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${1}`, `size=${20}`, `idParceiro=${id}`];
			commit(GET_TRANSIT_ORIGIN_FARM_REQUEST);
			http({
				method: 'GET',
				url: `${URL}/fazenda/origem/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(GET_TRANSIT_ORIGIN_FARM_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(GET_TRANSIT_ORIGIN_FARM_FAILED);
					reject(error);
				});
		});
	},
	[GET_TRANSIT_DESTINY_PARTNER_REQUEST]: ({ commit }: any, params: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${1}`, `size=${20}`];
			if (params.search) queryParams.push(`name=${params.search}`);
			commit(GET_TRANSIT_DESTINY_PARTNER_REQUEST);
			http({
				method: 'GET',
				url: `${URL}/parceiro/destino/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(GET_TRANSIT_DESTINY_PARTNER_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(GET_TRANSIT_DESTINY_PARTNER_SUCCESS);
					reject(error);
				});
		});
	},
	[GET_TRANSIT_DESTINY_FARM_REQUEST]: ({ commit }: any, id: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${1}`, `size=${20}`, `idParceiro=${id}`];
			commit(GET_TRANSIT_DESTINY_FARM_REQUEST);
			http({
				method: 'GET',
				url: `${URL}/fazenda/destino/busca?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(GET_TRANSIT_DESTINY_FARM_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(GET_TRANSIT_DESTINY_FARM_FAILED);
					reject(error);
				});
		});
	},
	[POST_TRANSIT_NEW_ORDER]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'POST',
				url: URL,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
