/* Imports */
import CompanyState from '@/models/company-state/company-state.interface';
import api from '@/shared/util/api';
const { http } = api.getInstance();

const BASE_URL = '/uboi-escalador';

import {
	ESCALATOR_GET_COMPANY_REQUEST,
	ESCALATOR_GET_COMPANY_REQUEST_SUCCESS,
	ESCALATOR_GET_COMPANY_REQUEST_FAIL,
} from './actions';

const state: CompanyState = {
	loadCompany: '',
	companyList: [],
};

/* Vuex Auth Getters */
const getters = {
	loadCompany: (state: CompanyState): string => state.loadCompany,
};

const mutations = {
	[ESCALATOR_GET_COMPANY_REQUEST]: (state: CompanyState) => {
		state.loadCompany = 'loading';
	},

	[ESCALATOR_GET_COMPANY_REQUEST_SUCCESS]: (state: CompanyState) => {
		state.loadCompany = '';
	},

	[ESCALATOR_GET_COMPANY_REQUEST_FAIL]: (state: CompanyState) => {
		state.loadCompany = 'fail';
	},
};

const actions = {
	[ESCALATOR_GET_COMPANY_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];

			if (requestData.search) {
				queryParams.push(`name=${requestData.search}`);
			}

			commit(ESCALATOR_GET_COMPANY_REQUEST);
			http({
				method: 'get',
				url: `${BASE_URL}/parceiro/busca/transportadora?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(ESCALATOR_GET_COMPANY_REQUEST_SUCCESS);
					resolve(data);
				})
				.catch((error) => {
					commit(ESCALATOR_GET_COMPANY_REQUEST_FAIL);
					reject(error);
				});
		});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
