
import { Vue, Component, Prop } from 'vue-property-decorator';
import Label from '@/components/widgets/atoms/Label/Label.vue';
import Input from '@/components/widgets/atoms/Input/Input.vue';
import InputMoney from '@/components/widgets/atoms/InputMoney/InputMoney.vue';
import InputMask from '@/components/widgets/atoms/InputMask/InputMask.vue';

@Component({
	components: {
		Label,
		Input,
		InputMoney,
		InputMask,
	},
})
export default class FormInput extends Vue {
	/* Props */
	@Prop({ default: false })
	public taggable!: boolean;

	@Prop({ default: 'Label' })
	public label!: string;

	@Prop()
	public value!: string;

	@Prop({ default: 'input' })
	public name!: string;

	@Prop({ default: 'text' })
	public type!: string;

	@Prop({ default: 'default' })
	public variant!: string;

	@Prop({ default: null })
	public min!: number | null;

	@Prop({ default: null })
	public max!: number | null;

	@Prop()
	public placeholder!: string;

	@Prop({ default: undefined })
	public mask!: string | null | undefined;

	@Prop({ default: false })
	public money!: boolean;

	@Prop({ default: false })
	public disabled!: boolean;

	@Prop({ default: false })
	public required!: boolean;
}
