import {
	ESCALATOR_ORDER_ORIGIN_RANCHER_REQUEST,
	ESCALATOR_ORDER_ORIGIN_FARM_REQUEST,
	ESCALATOR_OPERATIONAL_SITUATION_REQUEST,
} from '@/store/escalator/filters/actions';

import { AvailableFilters } from '@/models/filters/available-filters.interface';

// This must be a function so the state of the filters can be erased from one page to the next
export const AvailableFiltersDefinitions = () => {
	const definition = {};

	definition[AvailableFilters.Date] = {
		startDateSelected: '',
		endDateSelected: '',
	};

	definition[AvailableFilters.Origin] = {
		rancherSelected: '',
		farmSelected: '',
		rancherDispatch: ESCALATOR_ORDER_ORIGIN_RANCHER_REQUEST,
		farmDispatch: ESCALATOR_ORDER_ORIGIN_FARM_REQUEST,
		isLoading: false,
	};

	definition[AvailableFilters.Destiny] = {
		rancherSelected: '',
		farmSelected: '',
		rancherDispatch: ESCALATOR_ORDER_ORIGIN_RANCHER_REQUEST,
		farmDispatch: ESCALATOR_ORDER_ORIGIN_FARM_REQUEST,
		isLoading: false,
	};

	definition[AvailableFilters.Situation] = {
		situationSelected: '',
		situationDispatch: ESCALATOR_OPERATIONAL_SITUATION_REQUEST,
		isLoading: false,
	};

	definition[AvailableFilters.PedidoIsUpdated] = {
		pedidoIsUpdatedSelected: '',
	};

	definition[AvailableFilters.Order] = {
		orderSelected: '',
	};

	return definition;
};
