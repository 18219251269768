export const PERMISSIONS = {
	USER_TYPES: {
		P: 'RANCHER',
		T: 'TRANSIT_FACILITY',
		E: 'ESCALATOR',
	},
	USER_URL: {
		RANCHER: 'rancher',
		TRANSIT_FACILITY: 'transit-facility',
		ESCALATOR: 'escalator',
	},
};
