
import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';
import Card from '@/components/widgets/molecules/Card/Card.vue';
import FormInput from '@/components/widgets/molecules/FormInput/FormInput.vue';
import FormDate from '@/components/widgets/molecules/FormDate/FormDate.vue';
import FormSelect from '@/components/widgets/molecules/FormSelect/FormSelect.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';
import CardCategory from '../CardCategory/CardCategory.vue';

import { formatDateISO } from '@/shared/util/formatDate';
import hasDuplicateValues from '@/shared/util/hasDuplicateValues';

import {
	ESCALATOR_POST_TRAVEL_REQUEST,
	ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST,
	ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST_2,
} from '@/store/escalator/orders/actions';

@Component({
	components: {
		Modal,
		Card,
		FormInput,
		FormDate,
		FormSelect,
		CardCategory,
		ButtonCustom,
	},
})
export default class TravelForm extends Vue {
	/* Props */
	@Prop({ required: true })
	public animalsRows!: Array<any>;

	@Prop({ required: true })
	public animals!: Array<any>;

	@Prop({ default: () => [] })
	public truckTypes!: Array<any>;

	@Prop({ type: String })
	public category!: string;

	@Prop({ type: String })
	public shippingForecast!: string;

	@Prop({ type: String })
	public landingForecast!: string;

	@Prop({ required: true })
	public index!: number;

	@Prop({ default: '' })
	public roadMap!: string;

	/* Data */
	private form: any = {
		truckType: null,
		dateInit: null,
		dateEnd: null,
		freight: 0,
		extra: 0,
		categoryAnimals: [],
		multiplier: 1,
	};

	private buttonBusy: any = {
		saveButton: false,
	};

	/* Lifecycle */
	mounted() {
		this.form.dateInit = this.shippingForecast;
		this.form.dateEnd = this.landingForecast;
	}

	/* Computed */
	get selectedOrder() {
		const isEscalator = this.category === 'scale';

		if (isEscalator) {
			return this.$store.getters.getSelectedOrigination;
		}

		return this.$store.getters.hasSelectedTransitOrder;
	}

	get isLoading() {
		return this.$store.getters.isTransitPostTravelLoading;
	}

	/* Methods */
	postTravel(params, index) {
		const isEscalator = this.category === 'scale';

		if (isEscalator) {
			return new Promise<void>((resolve, reject) => {
				resolve();
				this.$store
					.dispatch(ESCALATOR_ORIGINATION_POST_TRAVEL_REQUEST_2, {
						requestData: params,
						index: index,
					})
					.then(() => {
						resolve();
					})
					.catch((e) => {
						this.$toast.error(e);
						reject();
					});
			});
		}

		return new Promise<void>((resolve, reject) => {
			this.$store
				.dispatch(ESCALATOR_POST_TRAVEL_REQUEST, {
					pedidoId: this.selectedOrder,
					requestData: params,
				})
				.then(() => {
					resolve();
				})
				.catch((e) => {
					this.$toast.error(e);
					reject();
				});
		});
	}

	onClear() {
		this.form = {
			truckType: null,
			dateInit: null,
			dateEnd: null,
			freight: 0,
			extra: 0,
			categoryAnimals: [],
			multiplier: null,
		};
	}

	onSave(index) {
		console.log(`roteiro: ${this.roadMap}`);

		const animalsInfo = {
			bois: { qty: 0, size: 0 },
			vacas: { qty: 0, size: 0 },
			novilhas: { qty: 0, size: 0 },
			bezerros: { qty: 0, size: 0 },
		};

		const categories = this.form.categoryAnimals
			.map((category) => {
				const animal = category.animal?.value;
				if (!animal) return null;
				animalsInfo[animal].qty = category.quantity;
				animalsInfo[animal].size = parseFloat(this.animalsRows[1][animal]) * category.quantity;

				return animal;
			})
			.filter(Boolean);

		if (hasDuplicateValues(categories)) {
			return this.$toast.error('Viagem com categorias duplicadas');
		}

		const isScale = this.category === 'scale';

		let params = {};
		if (isScale) {
			params = {
				idPedido: this.selectedOrder,
				roteiro: this.roadMap,
				tipoCaminhao: this.form.truckType?.value || null,
				categoriaAnimal: categories.length > 0 ? categories.join(', ') : null,
				previsaoDataInicioViagem: formatDateISO(this.form.dateInit),
				previsaoDataFimViagem: formatDateISO(this.form.dateEnd),
				qtdBoi: animalsInfo.bois.qty,
				qtdVaca: animalsInfo.vacas.qty,
				qtdNovilho: animalsInfo.novilhas.qty,
				qtdBezerro: animalsInfo.bezerros.qty,
				valorFrete: this.form.freight,
				valorExtra: this.form.extra,
				multiplicador: this.form.multiplier ? this.form.multiplier : 1,
			};
		} else {
			params = {
				tipoCaminhao: this.form.truckType?.value || null,
				categoriaAnimal: categories.length > 0 ? categories.join(', ') : null,
				qtdBoi: animalsInfo.bois.qty,
				qtdVaca: animalsInfo.vacas.qty,
				qtdNovilho: animalsInfo.novilhas.qty,
				qtdBezerro: animalsInfo.bezerros.qty,
				pesoMedioBoi: animalsInfo.bois.size,
				pesoMedioVaca: animalsInfo.vacas.size,
				pesoMedioNovilho: animalsInfo.novilhas.size,
				pesoMedioBezerro: animalsInfo.bezerros.size,
				previsaoInicioViagem: formatDateISO(this.form.dateInit),
				previsaoFimViagem: formatDateISO(this.form.dateEnd),
				valorFrete: this.form.freight,
				valorExtra: this.form.extra,
			};
		}

		if (Object.keys(params).some((i) => i !== 'multiplicador' && !(params[i] || params[i] === 0))) {
			return this.$toast.error('Viagem com valores incompletos');
		}

		const cantMultiplier = this.form.categoryAnimals.some((category) => {
			const animal = category.animal?.value;
			const multiplier: number = this.form.multiplier || 1;
			return multiplier * category.quantity > this.animalsRows[0][animal];
		});

		if (cantMultiplier) {
			return this.$toast.error('Valores à multiplicar não correspondem');
		}

		this.buttonBusy.saveButton = true;

		const promiseList: any = [];

		promiseList.push(this.postTravel(params, index));

		Promise.all(promiseList)
			.then(() => {
				this.$toast.success('Viagens criadas com sucesso');
			})
			.catch((err) => {
				this.$toast.error('Erro ao criar viagem');
			})
			.finally(() => {
				this.buttonBusy.saveButton = false;
				this.form.multiplier = null;
			});
	}
}
