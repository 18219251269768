
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import CheckIcon from '@/components/icons/Check.vue';

import { RULES } from '@/constants/passwordRules';

@Component({
	components: {
		CheckIcon,
	},
})
export default class PasswordRules extends Vue {
	/* Props */
	@Prop({ required: true })
	public password!: string;

	@Prop({ default: true })
	public modal!: boolean;

	/* Data */
	private rules: Array<any> = this.getRules;

	/* Computed */
	get getRules() {
		return RULES.map((rule) => {
			return { ...rule, status: false };
		});
	}

	get allStatus() {
		return this.rules.every((rule) => rule.status === true);
	}

	/* Watcher */
	@Watch('password')
	passwordChange(value) {
		this.verifyPassword(value);
	}

	/* Lifecicle */
	mounted() {
		if (this.password) {
			this.verifyPassword(this.password);
		}
	}

	/* Methods */
	verifyPassword(value) {
		this.rules.forEach((rule) => {
			const regex = new RegExp(rule.regex);
			if (regex.test(value)) {
				rule.status = true;
			} else {
				rule.status = false;
			}
			return rule;
		});
	}
}
