/* Import Libs */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';

/* Import Login Views */
import Login from '@/views/Login/index.vue';
import LoginEscalador from '@/views/Login/Escalador/index.vue';
import LoginForm from '@/components/pages/login/organisms/LoginForm/index.vue';
import ForgotForm from '@/components/pages/login/organisms/ForgotForm/index.vue';

/* Import Two Factor Authentication Views */
import TwoFactorAuthentication from '@/views/TwoFactorAuthentication/index.vue';
import TwoAuthEscalador from '@/views/TwoFactorAuthentication/Escalador/index.vue';

/* Import Recover Views */
import RecoverPassword from '@/views/RecoverPassword/index.vue';
import RecoverEscalador from '@/views/RecoverPassword/Escalador/index.vue';

/* Import First Login Views */
import FirstLogin from '@/views/FirstLogin/index.vue';
import FirstLoginEscalador from '@/views/FirstLogin/Escalador/index.vue';

/* Import Escalator Views */
import Home from '@/views/Home/Home.vue';
import EscalatorFacility from '@/views/EscalatorFacility/index.vue';
import EscalatorFacilityOrder from '@/views/EscalatorFacility/Order/Order.vue';

import PermissionDenied from '@/views/PermissionDenied/PermissionDenied.vue';

import { AUTH_VERIFY_TOKEN } from '@/store/auth/actions';
import { SYSTEM_PERMISSIONS } from '@/constants/permissions';

/* Vue Router Setup */
Vue.use(VueRouter);

/* Auth Guards */
const authGuard = (to: any, from: any, next: any) => {
	store.dispatch(AUTH_VERIFY_TOKEN);
	if (store.getters.isAuthenticated) {
		return next();
	}
	next(`/login/${store.getters.loginType}`);
};

const userHasPermissions = (systemPermissions, userPermissions) => {
	if (systemPermissions.length > 1) {
		return systemPermissions.some((permission) => userPermissions.includes(permission));
	}
	return systemPermissions.every((permission) => userPermissions.includes(permission));
};

function validatePermissions({ to, from, next }, parent, permissions) {
	const role = store.getters.getUserRole;
	const userPermissions = store.getters.getUserPermissions;
	if (parent == role && userHasPermissions(permissions, userPermissions)) {
		return authGuard(to, from, next);
	}
	next(`/`);
}

/* Route Collections and Routes */
export const escalatorRoutes: Array<RouteConfig> = [
	{
		path: '/escalator',
		name: 'Escalador',
		redirect: '/escalator/orders',
		component: EscalatorFacility,
		meta: {
			roles: SYSTEM_PERMISSIONS.ESCALATOR.ORDER.ALL,
		},
		children: [
			{
				path: '/escalator/orders',
				name: 'Escalador Pedidos',
				meta: {
					roles: SYSTEM_PERMISSIONS.ESCALATOR.ORDER.ALL,
				},
				component: EscalatorFacilityOrder,
				beforeEnter: (to, from, next) =>
					validatePermissions(
						{ to, from, next },
						'ESCALATOR',
						SYSTEM_PERMISSIONS.ESCALATOR.ORDER.ALL,
					),
			},
		],
	},
];

export const mainRoutes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		beforeEnter: authGuard,
		children: [
			...escalatorRoutes,
			{
				path: '/permission-denied',
				name: 'Sem Permissão',
				component: PermissionDenied,
			},
		],
	},
];

export const unauthenticatedRoutes: Array<RouteConfig> = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
		redirect: '/login/escalador',
		children: [
			{
				path: 'escalador',
				name: 'LoginEscalador',
				alias: ['/login/escalador', '/escalador'],
				redirect: '/login/escalador/enter',
				component: LoginEscalador,
				children: [
					{
						path: 'enter',
						name: 'Entrar',
						meta: { route: 'escalador' },
						component: LoginForm,
					},
					{
						path: 'forgot',
						name: 'Esqueceu',
						meta: { route: 'escalador' },
						component: ForgotForm,
					},
				],
			},
		],
	},
	{
		path: '/first-login',
		name: 'Primeiro Acesso',
		component: FirstLogin,
		redirect: '/first-login/escalador',
		children: [
			{
				path: 'escalador',
				name: 'Primeiro Acesso Transportadora ',
				meta: { route: 'escalador' },
				component: FirstLoginEscalador,
			},
		],
	},
	{
		path: '/recover-password',
		name: 'Recuperar Senha',
		component: RecoverPassword,
		redirect: '/recover-password/escalator',
		children: [
			{
				path: 'escalator',
				name: 'Recuperar Escalador ',
				meta: { route: 'escalador' },
				component: RecoverEscalador,
			},
		],
	},
	{
		path: '/two-factor-auth',
		name: 'Autenticação',
		component: TwoFactorAuthentication,
		redirect: '/two-factor-auth/escalator',
		children: [
			{
				path: 'escalator',
				name: 'Autenticação Escalador ',
				meta: { route: 'escalador' },
				component: TwoAuthEscalador,
			},
		],
	},
];

/* Vue Router */
const router = new VueRouter({
	mode: 'history',
	base: process.env.VUE_APP_URL,
	routes: [...mainRoutes, ...unauthenticatedRoutes],
	linkActiveClass: 'menu__link--active',
	linkExactActiveClass: 'menu__link--exact-active',
});

const DEFAULT_TITLE = 'Uboi Web';
router.afterEach((to, from) =>
	Vue.nextTick(() => (document.title = to.name ? DEFAULT_TITLE + ' - ' + to.name : DEFAULT_TITLE)),
);

export default router;
