
import { Component, Vue } from 'vue-property-decorator';
import InputLogin from '@/components/widgets/atoms/InputLogin/InputLogin.vue';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';

import { RECOVER_PASSWORD } from '@/store/auth/actions';

@Component({
	components: {
		InputLogin,
		Button,
		ButtonCustom,
	},
})
export default class ForgotForm extends Vue {
	/* Data */
	private login: string | null = null;

	/* Computed */
	get routerName() {
		return this.$route.meta?.route;
	}

	get loginType() {
		switch (this.routerName) {
			default:
				return 'uboi-escalador';
		}
	}

	get isLoading() {
		return this.$store.getters.isLoginLoading;
	}

	/* Methods */
	recoverPassword() {
		const params = {
			login: this.login,
			type: this.loginType,
		};
		this.$store
			.dispatch(RECOVER_PASSWORD, params)
			.then(({ message }) => {
				this.$toast.success(message);
			})
			.catch(() => {
				this.$toast.error('Erro ao tentar recuperar senha');
			});
	}

	goLogin() {
		this.$router.push({ path: `/login/${this.routerName}/enter` });
	}
}
