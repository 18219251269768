
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Card from '@/components/widgets/molecules/Card/Card.vue';
import Multiselect from '@/components/widgets/atoms/Multiselect/Multiselect.vue';
import FiltersIcon from '@/components/icons/Config.vue';

import { AvailableFilters } from '@/models/filters/available-filters.interface';

import { AvailableFiltersDefinitions } from '@/models/filters/transit-facility/order-filters.interface';

@Component({
	components: {
		Card,
		FiltersIcon,
		Multiselect,
	},
})
export default class OrderFilters extends Vue {
	/* Props */
	@Prop({ default: false })
	public tab!: string;

	/* Data */
	private active = false;

	/* Ts Workaround to import the Enum */
	private filterDefinitions: any = this.definitions;

	private availableFilters: any = AvailableFilters;

	private disabled: boolean = false;

	private filtersOptions: any = {
		originRancher: [],
		originFarm: [],
		destinyRancher: [],
		destinyFarm: [],
		situation: [],
		pedidoIsUpdated: [{ value: true, text: 'Exibir pedidos com alteração' }],
	};

	/* Watcher */
	@Watch('originRancherSelected')
	onSelectOriginRancher(value) {
		this.filtersOptions['originFarm'] = [];
		this.getFarm(this.availableFilters.Origin, 'originFarm', value?.value);
	}

	@Watch('destinyRancherSelected')
	onSelectDestinyRancher(value) {
		this.filtersOptions['destinyFarm'] = [];
		this.getFarm(this.availableFilters.Destiny, 'destinyFarm', value?.value);
	}

	@Watch('orderSelected')
	onSelectOrder(value) {
		if (value) {
			this.disabled = true;
			Object.keys(this.filterDefinitions).forEach((index) => {
				if (+index !== this.availableFilters.Order) {
					Object.keys(this.filterDefinitions[index]).forEach((item) => {
						if (item.includes('Selected')) this.filterDefinitions[index][item] = '';
					});
				}
			});
			return;
		}
		this.disabled = false;
	}

	/* Computed */
	get definitions() {
		return AvailableFiltersDefinitions();
	}

	get originRancherSelected() {
		return this.filterDefinitions[this.availableFilters.Origin].rancherSelected;
	}

	get destinyRancherSelected() {
		return this.filterDefinitions[this.availableFilters.Destiny].rancherSelected;
	}

	get orderSelected() {
		return this.filterDefinitions[this.availableFilters.Order].orderSelected;
	}

	/* Lifecycle */
	created() {
		this.active = window.innerWidth > 1367;
		let index = 0;
		Object.keys(this.filterDefinitions).forEach((filter) => {
			Object.keys(this.filterDefinitions[filter]).forEach((item) => {
				if (item.includes('Dispatch')) {
					this.requestFilter(
						Object.keys(this.filtersOptions)[index],
						this.filterDefinitions[filter][item],
						filter,
					);
					index++;
				}
			});
		});
	}

	/* Methods */
	requestFilter(filter, endpoint: string, filterIndex): void {
		this.filterDefinitions[filterIndex].isLoading = true;
		this.$store
			.dispatch(endpoint, { page: 1, size: 20 })
			.then((response) => {
				const data =
					filter == 'tracking' ||
					filter == 'situation' ||
					filter == 'status'
						? response
						: response?.results;

				this.filtersOptions[filter] = data?.map((i) => {
					if (['originRancher', 'destinyRancher'].includes(filter)) {
						return {
							value: i.idParceiro,
							text: i.nome,
						};
					} else if (['originFarm', 'destinyFarm'].includes(filter)) {
						return {
							value: i.idEndereco,
							text: i.descricao,
						};
					} else if (filter === 'situation') {
						return {
							value: i.codigo,
							text: i.descricao,
						};
					} else if (filter == 'status') {
						return {
							value: i.codigo.toString(),
							text: i.descricao,
						};
					}
				});
			})
			.catch((e) => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.filterDefinitions[filterIndex].isLoading = false;
			});
	}

	getFarm(filter, field, rancherId) {
		this.filterDefinitions[filter].isLoading = true;
		this.$store
			.dispatch(this.filterDefinitions[filter].farmDispatch, { page: 1, size: 20, id: rancherId })
			.then(({ results }) => {
				this.filtersOptions[field] = results.map((i) => ({
					value: i.idEndereco,
					text: i.descricao,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.filterDefinitions[filter].isLoading = false;
			});
	}

	search(filter, type, field, value) {
		this.filterDefinitions[filter].isLoading = true;
		if (type === 'rancher') {
			this.$store
				.dispatch(this.filterDefinitions[filter].rancherDispatch, {
					page: 1,
					size: 20,
					search: value,
				})
				.then(({ results }) => {
					this.filtersOptions[field] = results.map((i) => ({ value: i.idParceiro, text: i.nome }));
				})
				.catch((e) => {
					this.$toast.error(e);
				})
				.finally(() => {
					this.filterDefinitions[filter].isLoading = false;
				});
		}
		if (type === 'farm') {
			this.$store
				.dispatch(this.filterDefinitions[filter].farmDispatch, { page: 1, size: 20, search: value })
				.then(({ results }) => {
					this.filtersOptions[field] = results.map((i) => ({
						value: i.idEndereco,
						text: i.descricao,
					}));
				})
				.catch((e) => {
					this.$toast.error(e);
				})
				.finally(() => {
					this.filterDefinitions[filter].isLoading = false;
				});
		}
	}

	clearFilters(): void {
		this.$emit('onClear');
		this.filterDefinitions = AvailableFiltersDefinitions();
	}

	applyFilters(): void {
		this.$emit('onSubmit', this.filterDefinitions);
	}
}
