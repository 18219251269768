const getMaskDefinition = (type: string) => {
	switch (type) {
		case 'phone':
			return ['(##) ####-####', '(##) #####-####'];
		case 'year':
			return ['####'];
		case 'cep':
			return ['#####-###'];
		case 'cnpj':
			return ['##.###.###/####-##'];
		case 'cpf':
			return '###.###.###-##';
		case 'cpf/cnpj':
			return ['###.###.###-##', '##.###.###/####-##'];
		case 'miles':
			return ['####.##', '#####.##'];
		default:
			return null;
	}
};

export default getMaskDefinition;
