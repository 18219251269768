import { render, staticRenderFns } from "./DisclosureTravelScale.vue?vue&type=template&id=3b7accf4&scoped=true&"
import script from "./DisclosureTravelScale.vue?vue&type=script&lang=ts&"
export * from "./DisclosureTravelScale.vue?vue&type=script&lang=ts&"
import style0 from "./DisclosureTravelScale.vue?vue&type=style&index=0&id=3b7accf4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7accf4",
  null
  
)

export default component.exports