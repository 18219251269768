
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class PointMapIcon extends Vue {
	/* Props */
	@Prop({ default: '#865946' })
	public color!: string;

	@Prop({ default: '0.4' })
	public stroke!: string;
}
