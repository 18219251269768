
import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Multiselect from '@/components/widgets/atoms/Multiselect/Multiselect.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';

import BooleanModal from '@/components/modals/BooleanModal/BooleanModal.vue';

import { formatDateHour } from '@/shared/util/formatDate';
import formatMoney from '@/shared/util/formatMoney';
import formatNumber from '@/shared/util/formatNumber';

import {
	ESCALATOR_SCALE_ORDER,
	ESCALATOR_SCALE_TO_TRANSIT_FACILITIES,
	ESCALATOR_SCALE_TO_JBS,
	ESCALATOR_ORDER_DETAIL_REQUEST,
} from '@/store/escalator/orders/actions';

@Component({
	components: {
		Modal,
		Table,
		Multiselect,
		ButtonCustom,
		BooleanModal,
	},
})
export default class ScaleOrderModal extends Vue {
	/* Data */
	private orderHeader: any = [
		{ key: 'idPedido', label: 'Pedido Uboi' },
		{ key: 'pecuaristaOrigem', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaOrigem', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'pecuaristaDestino', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaDestino', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'kmViagem', label: 'KM Viagem' },
		{ key: 'valorTotal', label: 'Valor Total' },
		{ key: 'previsaoEmbarque', label: 'Embarque Estimado' },
		{ key: 'previsaoDesembarque', label: 'Desembarque Estimado' },
	];

	private modal: any = {
		title: 'Enviar Transportadoras',
		content: 'Você está prestes a liberar esse pedido para qualquer transportadora fazer uma proposta. Deseja continuar?',
		data: null,
		busy: false,
	};

	private orderRows: any = [];

	private animalsHeader: any = [
		{ key: 'categoria', label: 'Categoria', tdClass: 'brown-td' },
		{ key: 'bois', label: 'Bois', thClass: 'light-gray-header text-bold' },
		{ key: 'vacas', label: 'Vacas', thClass: 'light-gray-header text-bold' },
		{
			key: 'novilhas',
			label: 'Novilhas',
			thClass: 'light-gray-header text-bold',
		},
		{
			key: 'bezerros',
			label: 'Bezerros',
			thClass: 'light-gray-header text-bold',
		},
	];

	private animalsRows: any = [];

	private busy: boolean = false;

	/* Computed */
	get showModal() {
		return this.$store.getters.hasSelectedScaleOrder;
	}
	get selectedOrder() {
		return this.$store.getters.getSelectedScaleOrder;
	}
	get isLoading() {
		return this.$store.getters.isScaleOrderLoading;
	}

	/* Methods */
	resetInfo() {
		this.animalsRows = [
			{
				categoria: 'Quantidade',
				bois: 0,
				vacas: 0,
				novilhas: 0,
				bezerros: 0,
			},
			{ categoria: 'Peso Médio', bois: 0, vacas: 0, novilhas: 0, bezerros: 0 },
		];
		this.$store.dispatch(ESCALATOR_SCALE_ORDER, null);
	}

	initInfo() {
		this.getInfo();
	}

	getInfo() {
		this.$store
			.dispatch(ESCALATOR_ORDER_DETAIL_REQUEST, this.selectedOrder)
			.then((response) => {
				this.orderRows = [
					{
						...response,
						valorTotal: formatMoney(response.valorTotal),
						previsaoEmbarque: formatDateHour(response.previsaoEmbarque),
						previsaoDesembarque: formatDateHour(response.previsaoDesembarque),
					},
				];
				const { animais } = response;
				const animalsRows = [
					{
						categoria: 'Quantidade',
						bois: animais?.qtdBoi || 0,
						vacas: animais?.qtdVaca || 0,
						novilhas: animais?.qtdNovilho || 0,
						bezerros: animais?.qtdBezerro || 0,
					},
					{
						categoria: 'Peso Médio',
						bois: formatNumber(animais?.pesoMedioBoi) || 0,
						vacas: formatNumber(animais?.pesoMedioVaca) || 0,
						novilhas: formatNumber(animais?.pesoMedioNovilho) || 0,
						bezerros: formatNumber(animais?.pesoMedioBezerro) || 0,
					},
				];

				this.animalsRows = animalsRows;

				delete response.viagens;
				delete response.animais;
				delete response.contratoGerado;
				delete response.tipo;
			})
			.catch(() => {
				this.$emit('message', 'Erro ao carregar pedido', 'error');
			});
	}

	confirmSendTransitFacilities() {
		this.$bvModal.show('ConfirmTransitScaleModal');
	}

	sendToJBS() {
		this.busy = true;
		this.$store
			.dispatch(ESCALATOR_SCALE_TO_JBS, this.selectedOrder)
			.then(() => {
				this.$emit('message', 'Pedido enviado com sucesso', 'success', true);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao enviar pedido', 'error', true);
			})
			.finally(() => {
				this.busy = false;
				this.close();
			});
	}

	onSendTransitFacilitiesAccept(){
		this.busy = true;
		this.$store
			.dispatch(ESCALATOR_SCALE_TO_TRANSIT_FACILITIES, this.selectedOrder)
			.then(() => {
				this.$emit('message', 'Pedido enviado com sucesso', 'success', true);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao enviar pedido', 'error', true);
			})
			.finally(() => {
				this.busy = false;
				this.close();
			});
		this.close();
	}

	close() {
		this.$bvModal.hide('ScaleOrderModal');
	}
}
