
import { Component, Vue } from 'vue-property-decorator';
import InputLogin from '@/components/widgets/atoms/InputLogin/InputLogin.vue';
import InputPassword from '@/components/widgets/atoms/InputPassword/InputPassword.vue';
import ReCaptcha from '@/components/widgets/atoms/ReCaptcha/ReCaptcha.vue';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';

import { AUTH_MFA_LOGIN_REQUEST } from '@/store/auth/actions';

@Component({
	components: {
		InputLogin,
		InputPassword,
		ReCaptcha,
		Button,
		ButtonCustom,
	},
})
export default class TwoFactorAuthForm extends Vue {
	/* Data */

	public isLoadingAuth: boolean = false;

	public backspaceAgain: boolean = false;

	public tokenApproved: string | null = null;

	public recheadLength: boolean = false;

	public addAbsolute: boolean = false;

	public newHash: string | null = null;

	public inputList = [
		{ id: 'token-input-0', model: null },
		{ id: 'token-input-1', model: null },
		{ id: 'token-input-2', model: null },
		{ id: 'token-input-3', model: null },
		{ id: 'token-input-4', model: null },
		{ id: 'token-input-5', model: null },
	];

	/* Computed */
	get routerName() {
		return this.$route.meta?.route;
	}

	get loginType() {
		switch (this.routerName) {
			default:
				return 'uboi-escalador';
		}
	}

	get isLoading() {
		return this.$store.getters.isLoginLoading;
	}

	/* Methods */
	pasteCode(e) {
		let pastedValue = e.clipboardData.getData('text');
		pastedValue = pastedValue.trim();

		if (pastedValue.length != 6) {
			return;
		}

		this.inputList[0].model = pastedValue.charAt(0);
		this.inputList[1].model = pastedValue.charAt(1);
		this.inputList[2].model = pastedValue.charAt(2);
		this.inputList[3].model = pastedValue.charAt(3);
		this.inputList[4].model = pastedValue.charAt(4);
		this.inputList[5].model = pastedValue.charAt(5);
	}

	focusOnFirstEmpty(param, index) {
		if (!param.model && index != 0) {
			const filteredList = this.inputList.filter((input) => !input.model);
			const firstEmptyId = filteredList[0].id;
		}
	}

	backInput(param, index) {
		this.recheadLength = false;
		this.tokenApproved = null;

		if (!param.model && index != 0) {
			if (!param.model && index != 0) {
				const backInput = this.$refs[`input-${index - 1}`] as HTMLElement;

				if (backInput) {
					backInput[0].focus();
				}
				this.backspaceAgain = !this.backspaceAgain;
				return;
			}
		}
	}

	nextInput(param, index) {
		this.tokenApproved = null;

		if (!param.model) {
			return;
		}

		if (param.model && index != 5) {
			this.$nextTick(() => {
				const nextInput = this.$refs[`input-${index + 1}`] as HTMLElement;

				if (nextInput) {
					nextInput[0].focus();
				}
			});
			return;
		}
	}

	normalizeToken() {
		return `${this.inputList[0].model}${this.inputList[1].model}${this.inputList[2].model}${this.inputList[3].model}${this.inputList[4].model}${this.inputList[5].model}`;
	}

	doMfaLogin() {
		const token = this.normalizeToken();
		const params = {
			token: token,
			hashcode: this.$route.query.hashcode,
			type: this.loginType,
			routerName: this.routerName,
		};

		this.$store
			.dispatch(AUTH_MFA_LOGIN_REQUEST, params)
			.then(() => {
				this.$router.push({ name: 'Home' });
			})
			.catch((e) => {
				this.$toast.error(e.response.data);
			});
	}
}
