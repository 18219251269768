
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import Multiselect from '@/components/widgets/atoms/Multiselect/Multiselect.vue';

import { formatCNPJ, formatCPF } from '@/shared/util/farmatCPF';

import { GET_TRANSIT_PARTNER_REQUEST } from '@/store/escalator/newOrder/actions';

@Component({
	components: {
		Multiselect,
	},
})
export default class TableSelect extends Vue {
	/* Props */
	@Prop({ required: true })
	public title!: any;

	@Prop({ required: true })
	public rows!: any;

	@Prop({ default: false })
	public isSearchable!: boolean;

	@Prop({ default: true })
	public isLoading!: boolean;

	@Prop({ required: true })
	public valueType!: any;

	@Model('change') readonly activeModel!: any;

	/* Data */
	private active: boolean = false;

	private partners: any = [];

	private partnerValue: any = null;

	/* Computed */
	get model() {
		return this.activeModel.text;
	}

	set model(value) {
		this.$emit('change', { text: value, id: this.valueType.id });
	}

	/* Lifecycle */
	mounted() {
		if (this.isSearchable) this.getPartners(null);
	}

	/* Watcher */
	@Watch('rows')
	onRowsEmpty(value) {
		if (value) this.partnerValue = null;
	}

	/* Methods */
	getPartners(search) {
		this.$store
			.dispatch(GET_TRANSIT_PARTNER_REQUEST, { search })
			.then((response) => {
				this.partners = response.results.map((partner) => ({
					value: partner.idParceiro,
					text: partner.nome,
					cpf: formatCPF(partner?.codCpf) || formatCNPJ(partner?.codCgc),
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}
}
