
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import PlusIcon from '@/components/icons/Plus.vue';
import Card from '@/components/widgets/molecules/Card/Card.vue';
import FormInput from '@/components/widgets/molecules/FormInput/FormInput.vue';
import TravelForm from '../../TravelForm/TravelForm.vue';
import DisclosureTravel from '../../DisclosureTravelScale/DisclosureTravelScale.vue';

import TravelFields from '@/models/contracts/travel-fields.interface';
import { formatDateHour, formatDateISO } from '@/shared/util/formatDate';
import formatMoney from '@/shared/util/formatMoney';
import formatNumber from '@/shared/util/formatNumber';

import {
	ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST,
	ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST_2,
	ESCALATOR_SELECT_ORIGINATION,
	ESCALATOR_TRUCK_TYPES_REQUEST,
	ESCALATOR_SHIPPING_COMPANY_REQUEST,
	TRANSIT_PUT_CONTRACT,
	ESCALATOR_CANCEL_ORDER_REQUEST,
	ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST,
	ESCALATOR_DELETED_TRAVEL,
	ESCALATOR_ORIGINATION_SET_POST_TRAVEL,
} from '@/store/escalator/orders/actions';

import {
	ESCALATOR_TRAVEL_GET_DRIVERS,
	ESCALATOR_GET_TRUCKS,
	ESCALATOR_GET_CARTS,
} from '@/store/escalator/travels/actions';

@Component({
	components: {
		Modal,
		Table,
		TravelForm,
		DisclosureTravel,
		Card,
		FormInput,
		Button,
		ButtonCustom,
		PlusIcon,
	},
})
export default class ContractModal extends Vue {
	/* Prop */
	@Prop({ default: false })
	public reload!: boolean;

	@Prop({ default: false })
	public isEdition!: boolean;

	@Prop({ default: false })
	public isCancel!: boolean;

	/* Data */
	private orderHeader: Array<any> = [
		{ key: 'idPedido', label: 'Pedido Uboi' },
		{ key: 'codigoInterno', label: 'Código Interno' },
		{ key: 'pecuaristaOrigem', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaOrigem', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'pecuaristaDestino', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaDestino', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'kmViagem', label: 'KM Viagem' },
		{ key: 'valorTotal', label: 'Valor Total' },
		{ key: 'previsaoEmbarque', label: 'Embarque Estimado' },
		{ key: 'previsaoDesembarque', label: 'Desembarque Estimado' },
	];

	private orderRows: Array<any> = [];

	private animalsHeader: Array<any> = [
		{ key: 'categoria', label: 'Categoria', tdClass: 'brown-td' },
		{ key: 'bois', label: 'Bois', thClass: 'light-gray-header text-bold' },
		{ key: 'vacas', label: 'Vacas', thClass: 'light-gray-header text-bold' },
		{
			key: 'novilhas',
			label: 'Novilhas',
			thClass: 'light-gray-header text-bold',
		},
		{
			key: 'bezerros',
			label: 'Bezerros',
			thClass: 'light-gray-header text-bold',
		},
	];

	private animalsRows: Array<any> = [
		{
			categoria: 'Quantidade',
			bois: '0',
			vacas: '0',
			novilhas: '0',
			bezerros: '0',
		},
		{ categoria: 'Peso Médio', bois: '0', vacas: '0', novilhas: '0', bezerros: '0' },
	];

	private selectOptions: any = {
		truckTypes: [],
		drivers: [],
		trucks: [],
		carts: [],
	};

	private buttonBusy: any = {
		saveButton: false,
		contractButton: false,
	};

	private form: any = {
		roadMap: null,
	};

	private roadMap: string = '';

	private shippingForecast: string = '';

	private landingForecast: string = '';

	private hasContract: boolean = false;

	private travelForms: Array<number> = [];

	private travels: Array<TravelFields> = [];

	private successCancelAction: boolean = false;

	private listUpdatedOrder: any = {};

	private updateItemsHeader: Array<any> = [
		{
			key: 'idPedidoAtualizado',
			label: 'Pedido Atualizado',
			thClass: 'red-td',
			tdClass: 'red-td',
		},
	];

	/* Computed */
	get showModal() {
		return this.$store.getters.hasSelectedOrigination;
	}

	get selectedScale() {
		return this.$store.getters.getSelectedOrigination;
	}

	get isLoading() {
		return (
			this.$store.getters.isOriginationLoading ||
			this.$store.getters.isCancelingOrigination ||
			this.$store.getters.isTransitPutTravelLoading ||
			this.$store.getters.isScaleDetailLoading
		);
	}

	get animalsOptions() {
		const animals: Array<any> = [];
		Object.keys(this.animalsRows[0]).forEach((item) => {
			if (this.animalsRows[0][item] > 0) {
				animals.push({
					value: item,
					text: item,
					max: this.animalsRows[0][item],
				});
			}
		});
		return animals;
	}

	get isTravelStatusSuccess() {
		return this.$store.state.transitOrders.putTravelStatus === 'success';
	}

	get hasSelectedPostTravel() {
		return this.$store.state.transitOrders.selectedPostTravel !== '';
	}

	get canConclude() {
		const total = this.animalsRows.find((row) => row.categoria === 'Quantidade');
		const totalBezerros = parseInt(total.bezerros);
		const totalBois = parseInt(total.bois);
		const totalNovilhos = parseInt(total.novilhas);
		const totalVacas = parseInt(total.vacas);

		const hasAllocatedAllAnimals = totalBezerros + totalBois + totalNovilhos + totalVacas === 0;

		return hasAllocatedAllAnimals;
	}

	getTotalOfAnimaisByName(animals: Array<any>, animalName: string): number {
		return animals
			.map((animal) => animal.find((i) => i.nome === animalName))
			.reduce((acc, item) => acc + item.quantidade, 0);
	}

	/* Watcher */
	@Watch('reload')
	onReload(value) {
		if (value) this.getInfo();
	}

	@Watch('hasSelectedPostTravel')
	onChangeTravelStatus() {
		const index = this.$store.getters.getSelectedPostTravel;
		if (index !== '') {
			this.onCreateTravel(index);
			this.$store.commit(ESCALATOR_ORIGINATION_SET_POST_TRAVEL, '');
		}
	}

	/* Methods */
	resetInfo() {
		this.travels = [];
		this.travelForms = [];
		this.animalsRows = [
			{
				categoria: 'Quantidade',
				bois: '0',
				vacas: '0',
				novilhas: '0',
				bezerros: '0',
			},
			{
				categoria: 'Peso Médio',
				bois: '0',
				vacas: '0',
				novilhas: '0',
				bezerros: '0',
			},
		];
		this.selectOptions = {
			shippingCompany: [],
			truckTypes: [],
			drivers: [],
			trucks: [],
			carts: [],
		};
		this.$store.dispatch(ESCALATOR_SELECT_ORIGINATION, null);
	}

	initInfo() {
		this.getInfo();
		this.getTruckTypes();
		this.getShippingCompany();
	}

	getInfo2() {
		this.$store
			.dispatch(ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST_2, this.selectedScale)
			.then((response) => {
				const {
					viagens,
					animais,
					previsaoDesembarque,
					previsaoEmbarque,
					categoriaAnimais,
					peso,
					qtdAnimais,
				} = response;

				this.hasContract = response.contratoGerado;
				this.listUpdatedOrder = null;
				this.listUpdatedOrder = response.listaDeAlteracoes;
				this.populateUpdateOrderTable();

				this.form.roadMap =
					response.viagens.map((viagem) => viagem.roteiro).filter(Boolean)[0] || '';

				const totalBoisIncluidos = viagens
					.map((e) => e.animais.find((e) => e.nome === 'Boi'))
					.reduce((total, item) => total + item.quantidade, 0);
				const totalVacasIncluidos = viagens
					.map((e) => e.animais.find((e) => e.nome === 'Vaca'))
					.reduce((total, item) => total + item.quantidade, 0);
				const totalNovilhosIncluidos = viagens
					.map((e) => e.animais.find((e) => e.nome === 'Novilho'))
					.reduce((total, item) => total + item.quantidade, 0);
				const totalBezerrosIncluidos = viagens
					.map((e) => e.animais.find((e) => e.nome === 'Bezerro'))
					.reduce((total, item) => total + item.quantidade, 0);

				const quantidadeBois = animais?.qtdBoi ? animais?.qtdBoi - totalBoisIncluidos : 0;
				const quantidadeVacas = animais?.qtdVaca ? animais?.qtdVaca - totalVacasIncluidos : 0;
				const quantidadeBezerros = animais?.qtdBezerro
					? animais?.qtdBezerro - totalBezerrosIncluidos
					: 0;
				const quantidadeNovilhos = animais?.qtdNovilho
					? animais?.qtdNovilho - totalNovilhosIncluidos
					: 0;

				this.animalsRows = [
					{
						categoria: 'Quantidade',
						bois: quantidadeBois,
						vacas: quantidadeVacas,
						novilhas: quantidadeNovilhos,
						bezerros: quantidadeBezerros,
					},
					{
						categoria: 'Peso Médio',
						bois: formatNumber(animais?.pesoMedioBoi) || 0,
						vacas: formatNumber(animais?.pesoMedioVaca) || 0,
						novilhas: formatNumber(animais?.pesoMedioNovilho) || 0,
						bezerros: formatNumber(animais?.pesoMedioBezerro) || 0,
					},
				];

				const traveIds = this.travels.map((o) => o.travelId);
				const newTravels = viagens
					.map((travel) => {
						return {
							travelId: travel.idViagem ?? travel.idPropostaViagem,
							companies: travel?.transportadora
								? {
										...travel.transportadora,
										value: travel.transportadora.idParceiro,
										text: travel.transportadora.nome,
								  }
								: null,
							driver: travel?.motorista
								? {
										...travel.motorista,
										value: travel.motorista.idMotorista,
										text: travel.motorista.nomeMotorista,
								  }
								: null,
							truck: travel?.caminhao
								? {
										value: travel.caminhao.idCaminhao,
										text: travel.caminhao.placa,
								  }
								: null,
							carts: travel?.carretas
								? travel.carretas.map((cart) => ({
										cart: { value: cart.idCarreta, text: cart.placa },
								  }))
								: [{ cart: null }],
							truckType: {
								value: travel.tipoCaminhao,
								text: travel.tipoCaminhao,
							},
							dateEnd: travel.previsaoFimViagem,
							dateInit: travel.previsaoInicioViagem,
							category: categoriaAnimais,
							quantity: qtdAnimais,
							size: formatNumber(peso),
							total: travel.valorTotal,
							freight: travel.valorFrete,
							extra: travel.pedagioBalsa,
							data: travel,
							animalsRows: JSON.parse(JSON.stringify(this.animalsRows)),
						};
					})
					.filter((travel) => travel.data.status !== 'C');

				newTravels.forEach((travel) => {
					if (!traveIds.includes(travel.travelId)) this.travels.push(travel);
				});

				const deleteIndex: number[] = [];
				//remove deleted travels
				this.travels.forEach((travel, i) => {
					if (!newTravels.map((o) => o.travelId).includes(travel.travelId)) deleteIndex.push(i);
				});

				deleteIndex.forEach((index) => {
					this.travels.splice(index, 1);
				});

				//

				this.shippingForecast = response.previsaoEmbarque;
				this.landingForecast = response.previsaoDesembarque;

				this.orderRows = [
					{
						...response,
						valorTotal: formatMoney(response.valorTotal),
						previsaoEmbarque: formatDateHour(response.previsaoEmbarque),
						previsaoDesembarque: formatDateHour(response.previsaoDesembarque),
					},
				];

				this.removeAllocatedAnimals(viagens);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao carregar contrato', 'error');
			});
	}

	getInfo() {
		this.$store
			.dispatch(ESCALATOR_ORIGINATION_ORDER_DETAIL_REQUEST, this.selectedScale)
			.then((response) => {
				const {
					viagens,
					animais,
					previsaoDesembarque,
					previsaoEmbarque,
					categoriaAnimais,
					peso,
					qtdAnimais,
				} = response;

				this.listUpdatedOrder = null;
				this.listUpdatedOrder = response.listaDeAlteracoes;
				this.populateUpdateOrderTable();

				this.hasContract = response.contratoGerado;

				this.form.roadMap =
					response.viagens.map((viagem) => viagem.roteiro).filter(Boolean)[0] || '';

				const totalBoisIncluidos = viagens
					.map((e) => e.animais.find((e) => e.nome === 'Boi'))
					.reduce((total, item) => total + item.quantidade, 0);
				const totalVacasIncluidos = viagens
					.map((e) => e.animais.find((e) => e.nome === 'Vaca'))
					.reduce((total, item) => total + item.quantidade, 0);
				const totalNovilhosIncluidos = viagens
					.map((e) => e.animais.find((e) => e.nome === 'Novilho'))
					.reduce((total, item) => total + item.quantidade, 0);
				const totalBezerrosIncluidos = viagens
					.map((e) => e.animais.find((e) => e.nome === 'Bezerro'))
					.reduce((total, item) => total + item.quantidade, 0);

				const quantidadeBois = animais?.qtdBoi ? animais?.qtdBoi - totalBoisIncluidos : 0;
				const quantidadeVacas = animais?.qtdVaca ? animais?.qtdVaca - totalVacasIncluidos : 0;
				const quantidadeBezerros = animais?.qtdBezerro
					? animais?.qtdBezerro - totalBezerrosIncluidos
					: 0;
				const quantidadeNovilhos = animais?.qtdNovilho
					? animais?.qtdNovilho - totalNovilhosIncluidos
					: 0;

				this.animalsRows = [
					{
						categoria: 'Quantidade',
						bois: quantidadeBois,
						vacas: quantidadeVacas,
						novilhas: quantidadeNovilhos,
						bezerros: quantidadeBezerros,
					},
					{
						categoria: 'Peso Médio',
						bois: formatNumber(animais?.pesoMedioBoi) || 0,
						vacas: formatNumber(animais?.pesoMedioVaca) || 0,
						novilhas: formatNumber(animais?.pesoMedioNovilho) || 0,
						bezerros: formatNumber(animais?.pesoMedioBezerro) || 0,
					},
				];

				this.travels = viagens
					.map((travel) => {
						return {
							travelId: travel.idViagem ?? travel.idPropostaViagem,
							companies: travel?.transportadora
								? {
										...travel.transportadora,
										value: travel.transportadora.idParceiro,
										text: travel.transportadora.nome,
								  }
								: null,
							driver: travel?.motorista
								? {
										...travel.motorista,
										value: travel.motorista.idMotorista,
										text: travel.motorista.nomeMotorista,
								  }
								: null,
							truck: travel?.caminhao
								? {
										value: travel.caminhao.idCaminhao,
										text: travel.caminhao.placa,
								  }
								: null,
							carts: travel?.carretas
								? travel.carretas.map((cart) => ({
										cart: { value: cart.idCarreta, text: cart.placa },
								  }))
								: [{ cart: null }],
							truckType: {
								value: travel.tipoCaminhao,
								text: travel.tipoCaminhao,
							},
							dateEnd: travel.previsaoFimViagem,
							dateInit: travel.previsaoInicioViagem,
							category: categoriaAnimais,
							quantity: qtdAnimais,
							size: formatNumber(peso),
							total: travel.valorTotal,
							freight: travel.valorFrete,
							extra: travel.pedagioBalsa,
							data: travel,
							animalsRows: JSON.parse(JSON.stringify(this.animalsRows)),
						};
					})
					.filter((travel) => travel.data.status !== 'C');

				this.shippingForecast = response.previsaoEmbarque;
				this.landingForecast = response.previsaoDesembarque;
				this.orderRows = [
					{
						...response,
						valorTotal: formatMoney(response.valorTotal),
						previsaoEmbarque: formatDateHour(response.previsaoEmbarque),
						previsaoDesembarque: formatDateHour(response.previsaoDesembarque),
					},
				];

				this.removeAllocatedAnimals(viagens);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao carregar contrato', 'error');
			});
	}

	private updateRows: Array<any> = [];

	populateUpdateOrderTable() {
		this.updateItemsHeader = [];
		this.updateItemsHeader.push({
			key: 'idPedidoAtualizado',
			label: 'Pedido Atualizado',
			thClass: 'red-td',
			tdClass: 'red-td',
		});
		this.updateRows = [];
		this.updateItemsHeader.push({
			key: this.listUpdatedOrder?.idPedido + '',
			label: this.listUpdatedOrder?.idPedido + '',
			thClass: 'light-gray-header text-bold',
		});

		if (this.listUpdatedOrder?.pecuaristaOrigem) {
			this.updateRows.push({
				idPedidoAtualizado: 'Pecuarista Origem',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.pecuaristaOrigem,
			});
		}
		if (this.listUpdatedOrder?.fazendaOrigem) {
			this.updateRows.push({
				idPedidoAtualizado: 'Fazenda Origem',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.fazendaOrigem,
			});
		}
		if (this.listUpdatedOrder?.pecuaristaDestino) {
			this.updateRows.push({
				idPedidoAtualizado: 'Pecuarista Destino',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.pecuaristaDestino,
			});
		}
		if (this.listUpdatedOrder?.KmEntrePontos) {
			this.updateRows.push({
				idPedidoAtualizado: 'KM Viagem',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.KmEntrePontos,
			});
		}
		if (this.listUpdatedOrder?.valorFrete) {
			this.updateRows.push({
				idPedidoAtualizado: 'Valor Total',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.valorFrete,
			});
		}
		if (this.listUpdatedOrder?.previsaoEmbarque) {
			const date = formatDateHour(this.listUpdatedOrder?.previsaoEmbarque);
			this.updateRows.push({
				idPedidoAtualizado: 'Embarque Estimado',
				[this.listUpdatedOrder?.idPedido]: date,
			});
		}
		if (this.listUpdatedOrder?.previsaoDesembarque) {
			const date = formatDateHour(this.listUpdatedOrder?.previsaoEmbarque);
			this.updateRows.push({
				idPedidoAtualizado: 'Desembarque Estimado',
				[this.listUpdatedOrder?.idPedido]: date,
			});
		}
		if (this.listUpdatedOrder?.qtdBoi) {
			this.updateRows.push({
				idPedidoAtualizado: 'Quantidade Bois',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.qtdBoi,
			});
		}
		if (this.listUpdatedOrder?.pesoMedioBoi) {
			this.updateRows.push({
				idPedidoAtualizado: 'Peso Bois',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.pesoMedioBoi,
			});
		}
		if (this.listUpdatedOrder?.qtdVaca) {
			this.updateRows.push({
				idPedidoAtualizado: 'Quantidade Vacas',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.qtdVaca,
			});
		}
		if (this.listUpdatedOrder?.pesoMedioVaca) {
			this.updateRows.push({
				idPedidoAtualizado: 'Peso Vacas',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.pesoMedioVaca,
			});
		}
		if (this.listUpdatedOrder?.qtdNovilho) {
			this.updateRows.push({
				idPedidoAtualizado: 'Quantidade Novilhas',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.qtdNovilho,
			});
		}
		if (this.listUpdatedOrder?.pesoMedioNovilho) {
			this.updateRows.push({
				idPedidoAtualizado: 'Peso Novilhas',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.pesoMedioNovilho,
			});
		}
		if (this.listUpdatedOrder?.qtdBezerro) {
			this.updateRows.push({
				idPedidoAtualizado: 'Quantidade Bezerros',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.qtdBezerro,
			});
		}
		if (this.listUpdatedOrder?.pesoMedioBezerro) {
			this.updateRows.push({
				idPedidoAtualizado: 'Peso Bezerros',
				[this.listUpdatedOrder?.idPedido]: this.listUpdatedOrder?.pesoMedioBezerro,
			});
		}
	}

	getShippingCompany() {
		this.$store
			.dispatch(ESCALATOR_SHIPPING_COMPANY_REQUEST)
			.then((response) => {
				this.selectOptions.shippingCompany = response.results.map((company) => ({
					value: company.idParceiro,
					text: company.nome,
				}));
			})
			.catch((e) => this.$toast.error(e));
	}

	getTruckTypes() {
		this.$store
			.dispatch(ESCALATOR_TRUCK_TYPES_REQUEST)
			.then((response) => {
				this.selectOptions.truckTypes = response.map((truck) => ({
					value: truck.descricao,
					text: truck.descricao,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getDrivers(search = null) {
		this.$store
			.dispatch(ESCALATOR_TRAVEL_GET_DRIVERS, { page: 1, size: 20, search })
			.then(({ results }) => {
				this.selectOptions.drivers = results.map((driver) => ({
					...driver,
					value: driver.idParceiro,
					text: driver.nome,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getTrucks(search = null) {
		this.$store
			.dispatch(ESCALATOR_GET_TRUCKS, { page: 1, size: 20, search })
			.then(({ results }) => {
				this.selectOptions.trucks = results.map((truck) => ({
					value: truck.idCaminhao,
					text: truck.placa,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getCarts(search = null) {
		this.$store
			.dispatch(ESCALATOR_GET_CARTS, { page: 1, size: 20, search })
			.then(({ results }) => {
				this.selectOptions.carts = results.map((cart) => ({
					value: cart.idCarreta,
					text: cart.placa,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	removeAllocatedAnimals(travels) {
		// index: 0 = Quantity animal row // index: 1 = Size animal row;
		travels.forEach((travel, index) => {
			Object.keys(travel).forEach((key) => {
				if (['qtdBoi', 'qtdVaca', 'qtdNovilho', 'qtdBezerro'].includes(key)) {
					const column = {
						qtdBoi: 'bois',
						qtdVaca: 'vacas',
						qtdNovilho: 'novilhas',
						qtdBezerro: 'bezerros',
					};
					this.animalsRows[0][column[key]] -= travels[index][key];
				}
			});
		});
		Object.keys(this.animalsRows[1]).forEach((animal) => {
			if (['bois', 'vacas', 'novilhas', 'bezerros'].includes(animal)) {
				this.animalsRows[1][animal] =
					this.animalsRows[0][animal] > 0 ? this.animalsRows[1][animal] : 0;
			}
		});
	}

	addTravel() {
		this.travelForms.push(this.travelForms.length + 1);
	}

	removeTravel(index) {
		this.travelForms.splice(index, 1);
	}

	onCreateTravel(index) {
		this.removeTravel(index);
		this.getInfo2();
	}

	onUpdateTravel() {
		this.travels = [];
		this.travelForms = [];
		this.animalsRows = [
			{
				categoria: 'Quantidade',
				bois: '0',
				vacas: '0',
				novilhas: '0',
				bezerros: '0',
			},
			{
				categoria: 'Peso',
				bois: '0',
				vacas: '0',
				novilhas: '0',
				bezerros: '0',
			},
		];
		this.selectOptions = {
			truckTypes: [],
			drivers: [],
			trucks: [],
			carts: [],
		};
		this.getInfo2();
	}

	onSave(loader = true) {
		const travels = this.travels.map((travel) => ({
			idPropostaViagem: travel.travelId,
			idMotorista: travel.driver?.value || null,
			idCaminhao: travel.truck?.value || null,
			idCarretas: travel.carts.map((cart) => cart.cart?.value || null).filter(Boolean),
		}));

		const requestData = {
			idProposta: this.selectedScale,
			roteiro: this.form.roadMap,
			viagens: travels,
		};

		if (loader) this.buttonBusy.saveButton = true;
		return new Promise((resolve, reject) => {
			this.$store
				.dispatch(TRANSIT_PUT_CONTRACT, requestData)
				.then(() => {
					if (loader) this.$toast.success('Proposta de contrato salva com sucesso');
					resolve('success');
				})
				.catch((err) => {
					if (loader) this.$toast.error('Erro ao salvar proposta de contrato');
					reject(err);
				})
				.finally(() => {
					if (loader) this.buttonBusy.saveButton = false;
				});
		});
	}

	conclude() {
		const travels = this.travels;

		let hasError = false;
		const params = travels.map((travel) => {
			if (hasError) return;

			try {
				const promise = this.validateTravelParams(travel);
				return promise;
			} catch (err) {
				hasError = true;
				this.$toast.error(err?.message);
				return;
			}
		});

		const calls = params.map((params) =>
			this.$store.dispatch(ESCALATOR_ORIGINATION_PUT_TRAVEL_REQUEST, params),
		);

		Promise.all(calls)
			.then(() => {
				this.$toast.success('Viagens atualizadas com sucesso!');
			})
			.catch(() => {
				this.$toast.error('Erro ao atualizar viagens!');
			});
	}

	validateTravelParams(travel) {
		const qtdBezerro = travel.data.animais.find((animal) => animal.nome === 'Bezerro').quantidade;
		const qtdBoi = travel.data.animais.find((animal) => animal.nome === 'Boi').quantidade;
		const qtdNovilho = travel.data.animais.find((animal) => animal.nome === 'Novilho').quantidade;
		const qtdVaca = travel.data.animais.find((animal) => animal.nome === 'Vaca').quantidade;

		const hasShippingCompany = travel.companies?.value;

		if (!hasShippingCompany) {
			// return this.$toast.error('Por favor, selecione uma transportadora');
			throw new Error('Por favor, selecione uma transportadora');
		}

		const params = {
			idViagem: travel.travelId,
			idTransportadora: travel.companies.value,
			qtdBoi,
			qtdVaca,
			qtdNovilho,
			qtdBezerro,
			roteiro: travel.data.roteiro,
		};

		const { driver, truck, carts, data, dateInit, dateEnd } = travel;

		if (driver?.value) {
			params['idMotorista'] = driver.value;
		}

		if (truck?.value) {
			params['idCaminhao'] = truck.value;
		}

		if (carts.length) {
			params['idCarretas'] = carts.map((cart) => cart.cart.value);
		}

		if (dateInit) {
			params['previsaoInicioViagem'] = formatDateISO(dateInit);
		}
		if (dateEnd) {
			params['previsaoFimViagem'] = formatDateISO(dateEnd);
		}

		const { truckType } = this.form;

		if (data.previsaoInicioViagem) {
			params['previsaoInicioViagem'] = data.previsaoInicioViagem;
		}

		if (data.previsaoFimViagem) {
			params['previsaoFimViagem'] = data.previsaoFimViagem;
		}

		if (truckType?.value) {
			params['tipoCaminhao'] = this.form.truckType.value;
		}
		if (travel.truckType.value) {
			params['tipoCaminhao'] = travel.truckType.value;
		}

		if (Object.keys(params).some((i) => !(params[i] || params[i] === 0))) {
			// return this.$toast.error('Viagem com valores incompletos');
			throw new Error('Viagem com valores incompletos');
		}

		return params;
	}

	handleUpdateForm(form) {
		this.travels = this.travels.map((travel) => {
			if (travel.travelId === form.travelId) {
				return { ...travel, ...form };
			}

			return travel;
		});
	}

	handleConfirmCancel() {
		const id = this.getOrderId();
		this.$bvModal.hide('modal-confirmation-cancel');

		this.$store
			.dispatch(ESCALATOR_CANCEL_ORDER_REQUEST, id)
			.then(() => {
				this.$emit('message', 'Pedido cancelado com sucesso', 'success', true);
				this.$store.dispatch(ESCALATOR_SELECT_ORIGINATION, null);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao cancelar pedido', 'error');
			});
	}

	getOrderId() {
		return this.$store.getters.getSelectedOrigination;
	}

	close() {
		this.$bvModal.hide('ScaleModal');
	}

	/* Watcher */
	@Watch('form.roadMap')
	onDataChange(value) {
		this.roadMap = value;
	}

	@Watch('updateFromDeleted')
	onDeletedTravel() {
		if (this.$store.state.transitOrders.deletedTravel === 'deleted') {
			this.getInfo2();
			this.$store.commit(ESCALATOR_DELETED_TRAVEL, '');
		}
	}

	get updateFromDeleted() {
		return this.$store.state.transitOrders.deletedTravel === 'deleted';
	}
}
