const hasDuplicateValues = (value: Array<any>) => {
	const arrayBase: Array<any> = [];
	return value.some((element) => {
		if (!arrayBase.includes(element)) {
			arrayBase.push(element);
			return false;
		}
		return true;
	});
};

export default hasDuplicateValues;
