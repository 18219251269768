const ESCALATOR_PERMISSIONS = {
	ESCALATOR: {
		ORDER: {
			ALL: [],
		},
		TRAVEL: {
			ALL: [],
		},
		MAP: {
			ALL: [],
		},
		REGISTER: {
			ALL: [],
		},
		PERMISSIONS: {
			ALL: [],
		},
	},
};

export const SYSTEM_PERMISSIONS = Object.freeze({
	...ESCALATOR_PERMISSIONS,
});
